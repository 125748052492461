import React, { Component } from 'react';
import FlashMessage from './../Utils/flashMessage.js'
import StickyMessage from '../Utils/StickyMessage.js';


 class Body extends Component {

	
	render() {
		
		return (
			<div className="fluid-container">

				<div className="col-md-12 mainContent">

					<StickyMessage />
					<FlashMessage />

					<div className={"bg-container"}>
						{this.props.children}
					</div>
				</div>

						

					


			</div>
			
		);
	}
}

export default Body;