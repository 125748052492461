import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const ClockIn = ({ data, active, ...props }) => {

  const url = data && data.location ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z` : "";

  if (data)
  
    return (
      <>
        <b>Start date & time :</b> {moment(data.finished_at).format("YYYY-MM-DD HH:mm:ss")}
        {data.location && data.location.lat && (
          <a href={url} className={"ml-2"} target="_blank">
            <FontAwesomeIcon icon={faMapMarker} /> Localisation
          </a>
        )}
        <br />
        <br />
        <b>Photos</b>
        <br />
        {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              <div key={"clock_photo_"+key} className={"test-photos-single"}>
                <PhotoItem photo={photo} active={1} />
              </div>
            ))}
          </div>
        )}
      </>
    );
  else return <>Didn't clock in</>;
};

export default ClockIn;
