import React , { useState, useRef, useEffect, useCallback}from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave, faInfoCircle, faSpinner, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import { connect } from "react-redux";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import Restricted from "../../../Utils/Restricted";
import { MapContainer, TileLayer, Marker, Popup , GeoJSON , FeatureGroup} from 'react-leaflet';
// import { EditControl } from "react-leaflet-draw"
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/images/marker-shadow.png";
import { circle, Icon } from "leaflet";
import ReactLeafletKml from 'react-leaflet-kml';
import rewind from "@mapbox/geojson-rewind";
import * as tj from "@mapbox/togeojson";
import Constants from "../../../Utils/Constants";
import { CirclePicker, SketchPicker , TwitterPicker} from 'react-color';


const CoSiteColor = ({id,color,setColor,  ...props}) => {
//console.log(file)
    //const [color , setColor] = useState('#7bfc03')
    
    // useEffect(() => {
    //         //selectAsset();
    // }, []); 
 
    // useEffect(() => {
    //     const results = data.filter((d) =>
    //       d[2].toLowerCase().includes(searchTerm)
    //     );
    //     setSearchResults(results);
    //     setJsonData(results)
    // }, [searchTerm]);
    
//   const submitUploadMap = (e) =>{
    
//     e.preventDefault();
//     const data = new FormData(e.target);
//         api.post(e.target.action+'/'+ this.props.item.id, data, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         })
//         .then(res => {
//         })
       
//   }

    // const  loadKML = () => {
    //     //let that = this
    //     api.get(`/api/cosite/getfile/${id}`).then(res => {
    //       if(res.data){
    //             const parser = new DOMParser();
    //             let kml = parser.parseFromString(res.data.file, "text/xml");
    //             setFile(kml)
    //       }
    //     });
    // }

    const twitterStyle= {
      default: {
          input: {
              display: "none",
              visibility:"hidden"
          },
          hash: {
              display: "none",
              visibility:"hidden"
          }
      }
    };

    const _onCreated = e  => { 
        console.log('create')
        // this.setState({
        //   color: '#FCB900'
        // })
      } 
    
    const _onEdited = e  => { 
        console.log('edit')
      } 
    const _onDeleted = e  => { 
        console.log('delete')
      } 
    // const setColor  = (color) => {
    //     // let that = this
    //     console.log(color)
    //     setColor(color.hex)
    //     // that.setState({
    //     //   color: color
    //     // })
    // }


    return (
        <React.Fragment>
         
                <div className="mt-2">
                    

                    
                    <TwitterPicker colors={['#0fcc04', '#e41a1a', '#8341c2', '#f0eb29','#70c3f4','#d2d4dc','#435560']} onChange={ setColor} styles={twitterStyle}/>
                    
                </div>
            
        </React.Fragment>
    )
}
export default CoSiteColor;
