import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";


export default ({overlay, hideOverlay, ...props}) => {

    return (
        <div className={"loading-overlay"+(overlay.enabled? " open": "")}> 

            <div className={"text"}>
                {overlay.label ? <FontAwesomeIcon icon={overlay.type === "fail" ? faTimes : faCheck} className={overlay.type === "fail" ? "overlay-fail" : "overlay-success"} /> : "" }
                {overlay.message}
                {overlay.button ? 
                (<div className={"overlay-button"}>
                    <a target={overlay.button.type === 'link' ? "_blank" : ""}  href={overlay.button.link} onClick={hideOverlay}>
                    <button type="button" className={"btn btn-primary mt-3" + (overlay.type === "fail" ? " btn-danger" : " btn-success") }>
                        {overlay.button.label}
                    </button>
                    </a>
                </div>)
                : ""}
            </div>
        </div>
    )
}