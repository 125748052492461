import React from "react";



export default ({active, message, ...props}) => {
    return (
        <div className={"loading-overlay"+(active? " open": "")}> 
            <div className={"text"}>{message}</div>
        </div>
    )
}