import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading, setFlash, closeFlash } from '../../Redux/actions/index';
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import ReturnOverlay from './components/ReturnOverlay';
import Constants from '../../Utils/Constants';
import TaskRow from './Delivery/TaskRow';
import ApiService from '../../Utils/ApiService';
import TaskGlobalRow from'./TaskGlobalRow';
const mapStateToProps = state => {
	return {
		session: state.session
	}
};

const mapDispatchToProps = (dispatch) =>
	({
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e)),
		setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
	});
class TaskGlobal extends Component{
    constructor(props){
        super(props);
        this.state={
            rows: [],
			showOverlay: false,
			taskid: "",
			agentid: "",
			selected_pm: "",
			activeTest: "clockin",
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: false,
			showReturnOverlay: false   
        }
    }
    toggleOverlay = () => this.setState({showOverlay: !this.state.showOverlay});

	selectTest = (e, test) => {

		if(window.ga)
			window.ga('send', 'event', 'TaskList', 'selectTest');

		e.preventDefault();
		this.setState({ activeTest: test })
	}
    deleteTask = (pmid) => {

		//const pmid = e.target.getAttribute('data-task-id');

		this.setState({
			selected_pm: pmid,
			showDeleteOverlay: true,
			deleteSteps: 1
		})
	}
    nextDeleteStep = () => {

		this.setState({
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: true,
			deleteSteps: 2
		})
	}
    confirmDelete = () => {

		this.props.isLoading();
		this.setState({ showDeleteOverlayConfirmation: false })

		const data = {
			pmid: this.state.selected_pm
		}

		api.post('/api/pm/delete', data)
			.then(() => {
				this.props.handlers.loadTasks()
			})
	}
    exportPM = (pm, anomalies) => {

		if(window.ga)
			window.ga('send', 'event', 'TaskList', 'exportPM');

		// let remote = Constants.apiURL;

		if(anomalies) {
			this.pmExportForm.action = Constants.apiURL+"/api/anomalies/checks_export/"+pm.task_id;
		} else {
			this.pmExportForm.action = Constants.apiURL+"/api/pm/export/igeneral/"+pm.task_id;
			//this.pmExportForm.action = Constants.apiURL+"/api/pm/export/pickup/"+pm.task_id;
		}

		this.pmInput.value = JSON.stringify(pm)
		this.userInfo.value = JSON.stringify(this.props.session.user)
		this.props.isLoading();
		this.pmExportForm.submit();
		this.props.doneLoading();

	}
    toggleReturnOverlay = () => this.setState({showReturnOverlay: !this.state.showReturnOverlay})

	returnTask = (task) => {

		this.setState({
			selected_pm: task,
			showReturnOverlay: true
		})
	}
    closeTask = taskid => {

		this.props.isLoading({ message: "Mise a jour en cours." });

		ApiService.closeTask(taskid)
			.then(res => {
				this.props.handlers.loadTasks();
				this.props.doneLoading();
			})
	}


    render(){
        const data = this.props.data;
        return(
         <React.Fragment>
			   <ReturnOverlay
					open={this.state.showReturnOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleReturnOverlay}
					reload={this.props.handlers.loadTasks}
					{...this.props}
				/>
				<form method="post" ref={ref => this.pmExportForm = ref}>
					<input type="hidden" name={"pm"} ref={ref => this.pmInput = ref} />
					<input type="hidden" name={"token"} value={this.props.session.user.token} />
					<input type="hidden" name={"userInfo"} ref={ref => this.userInfo = ref} />
				</form>
				<div className={this.state.showDeleteOverlay? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => this.setState({showDeleteOverlay: false }) }
							className={"ui-overlay-close btn btn-danger btn-sm"}>
						<FontAwesomeIcon icon={faTimes} />
						</button>
						<h5 className={"mb-4"}>Voulez-vous vraiment supprimer cette Tache ?</h5>
						<button type="submit" className={"btn btn-success btn-sm mt-2"}>ANNULER</button>
						<button onClick={this.nextDeleteStep} type="submit" className={"btn btn-danger btn-sm ml-2 mt-2"}>OUI, SUPPRIMER</button>
					</div>
				</div>
             <div className="col-md-12 ctable mb-3">
  					<div className={"d-none d-sm-block"}>
  						<div className={"row head-titles ctable-row"}>
                              <div className={"col-md-1"}> ID </div>
                              <div className={"col-md-1"}><Trans i18nKey="label_agent" /></div>
                              <div className={"col-md-1"}> Task Type  </div>
                              <div className={"col-md-2"}> <Trans i18nKey="label_datetime" /></div>
                              <div className={"col-md-2"}>Etapes Tache</div>
							
                              <div className={"col-md-1"}>Site Pickup</div>
							  <div className={"col-md-1"}>Site Drop </div>
                              <div className={"col-md-3 task-details-col"}><Trans i18nKey="label_details" /></div>
								
							
						 
                        </div>
                    </div>
                    {data.tasks.map((task, i) =>
						<TaskGlobalRow
							key={task.task_id}
							exportPM={this.exportPM}
							task={task}
							handlers={this.props.handlers}
							deleteTask={this.deleteTask}
							returnTask={this.returnTask}
							closeTask={this.closeTask}
						/>
					)}
					{data.tasks.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucune Tache trouvée</div>)}

                </div>

         </React.Fragment>

        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskGlobal);
