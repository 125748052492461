import React, { useState, useRef } from "react"
import api from "../../../Utils/Api";


export default class Config extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            task_pm_delay: ""
        };


        this.props.isLoading({
            message: "Chargement",
            animated: true
        })
    }

    componentDidMount() {
        api.get('/api/settings/info')
        .then(res => {
            this.setState({
                    ...res.data
            });

            this.props.doneLoading()
        })
    }

    saveSettings = (e) => {
        e.preventDefault();



        let that = this;

		// this.props.isLoading({
		// 	message:"Mise à jour du site",
		// 	animate:true
		// });

		const formdata = new FormData(e.target);

		
		let data = {};

		for(var [key, value] of formdata.entries()) 
			data[key] = value
		


        this.props.isLoading({
            message: "Mise à jour en cours",
            animated: true
        });
        
		api.post('/api/settings/info/update', data)
		.then((response) => {

			this.props.doneLoading();
			
        })

    }

    render() {
        return (
            <>
            <h3>Configuration</h3>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <form onSubmit={this.saveSettings}>

                        <div className={"form-group form-inline custom-inline-form"}>
                            <label>Marge délais PM</label>
                            {this.state.task_pm_delay ? (<input type="number" name="task_pm_delay" defaultValue={this.state.task_pm_delay}  className={"form-control"} />) : ""}
                        </div>

                        <button type="submit" className={"btn btn-primary"}>Sauvegarder</button>
                    </form>

                </div>
            </div>
            </>
        )
    }
}