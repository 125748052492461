import React, { Component } from "react";
import set from "lodash/set";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TaskList from "../../Components/Task/SSR/TaskList";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import Restricted from "../../Utils/Restricted";
import SelectFilter from "../../Utils/Filter/Elements/SelectFilter";
import ApiService from '../../Utils/ApiService';


const mapStateToProps = state => {
  return {
    session: state.session,
    filters: state.filters
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e))
  };
}

class TaskListContainer extends Component {

  constructor(props) {

    super(props);

    this.props.isLoading({
      message: "Loading tasks",
      animate: true
    });

    this.state = {
      taskid: null,
      total: 0,
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      types: [],
      rows: [],
      current: 1,
      pages: 1,
      limit: 15,
      filters: {},
    };

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks.bind(this),
      updateFilter: this.updateFilter.bind(this)
    };
  }

  changeLimit = e => {

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };

  updateFilter = (event, name) => {

    const value = event.target ? event.target.value : event.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value
        }
      },
      () => {
        this.props.isLoading();
        this.loadTasks(1);
      }
    );
  };

  componentDidMount() {

    if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    } else this.loadTasks();
  }

  componentDidUpdate(prev) {

    // if(this.props.match.params.taskid && this.props.match.params.taskid) {
    // 	this.setState({
    // 		taskid: this.props.match.params.taskid
    // 	})
    // }
  }

  loadTasks(p = this.state.current) {

    let nstate = this.state;
    let that = this;
    let crits = buildFilters(this.state.filters);

    api.get(`/api/ssr/task/fetch?${crits}&page=${p}&limit=${this.state.limit}`).then(function(response) {
      if (response.data.success) {
        response.data.tasks.map(() => {
          nstate.rows.push({ hidden: true });
        });
        that.setState({
          tasks: response.data.tasks,
          pages: response.data.pages,
          rows: nstate.rows,
          total: response.data.total
        });
      } else {
        if (response.data.errcode === 101) {
          that.props.setAuth(false);
        }
      }
      that.props.doneLoading();
    });
  }

  exportAnomalies = e => {

    if (window.ga) window.ga("send", "event", "TaskList", "exportAnomalies");

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + `/api/ssr/task/fetch?${crits}&limit=-1&anomalies`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  exportPMS = e => {

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + `/api/ssr/task/fetch?${crits}&limit=-1&export=true`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  selectPage = p => {

    if (window.ga) window.ga("send", "event", "TaskList", "selectPage");

    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  exportTMDB = (e) => {

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + Constants.apiEndPoints.EXPORT_TMDB;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  }


  render() {
    console.log('owner => '+ this.props.session.user.owner )
    return (
      <React.Fragment>
        <div className={"container-header"}>
          SSR Tasks
          {!this.props.session.auth ? <Redirect to="/login" /> : ""}
        </div>

    
        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {/* <form action="" method="GET"  onSubmit={e => this.exportAnomalies(e)} >
						<button type="submit" className={"btn btn-primary btn-block mb-2"}>Exporter les anomalies <FontAwesomeIcon className={"ml-2"} icon={faFileExport} /></button>
						</form> */}

              <form action="" method="POST" onSubmit={e => this.exportPMS(e)}>
                <input type="hidden" name="token" value={this.props.session.user.token} />
                <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                  Exporter la liste
                </button>
              </form>
              {((this.props.session.user.role === "manager" && this.props.session.user.owner == 'ooredoo') || this.props.session.user.role === "admin") &&
              <form action="" method="POST" onSubmit={e => this.exportTMDB(e)}>
                <input type="hidden" name="token" value={this.props.session.user.token} />
                <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                    Exporter TMDB inputs
               </button>
               
              </form> }

              <h4>Recherche</h4>

              <Restricted role={"manager"}>
                <div className={"form-group"}>
                  <label>Zone owner</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[...this.props.filters.site_owners]}
                    // placeholder="O&M, Sous-traitant..."
                    onChange={e => this.updateFilter(e, "site_owner")}
                  />
                  {/* <SelectFilter 
                    label="Site Owner"
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.site_owners]}

                  /> */}
                </div>
              </Restricted>

              <Restricted role={"cluster_manager"}>
                <div className={"form-group"}>
                  <label>Manager </label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.managers]}
                    placeholder="Manager"
                    onChange={e => this.updateFilter(e, "site_manager")}
                  />
                </div>
              </Restricted>

              {/*<div className={"form-group"}>
                <label>Cluster</label>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[{ label: "Tous", value: "" }, ...this.props.filters.clusters]}
                  placeholder="Séléctionnez..."
                  onChange={e => this.updateFilter(e, "site_cluster")}
                />
              </div>*/}

              <div className={"form-group"}>
                <label>Wilaya</label>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[{ label: "Tous", value: "" }, ...this.props.filters.wilayas]}
                  placeholder="Séléctionnez..."
                  onChange={e => this.updateFilter(e, "site_wilaya")}
                />
              </div>

              {this.props.session.user.role !== "agent" && (
                <div className={"form-group"}>
                  <label>Agent</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.agents]}
                    placeholder="Agent"
                    onChange={e => this.updateFilter(e, "agent")}
                  />
                </div>
              )}

                <div className={"form-group"}>
                  <label>Status</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={this.props.filters.task_status}
                    placeholder="Status"
                    onChange={e => this.updateFilter(e, "task_status")}
                  />
                </div>

              <div className={"form-group"}>
                <label>Date début</label>
                <input type="date" className={"form-control"} onChange={e => this.updateFilter(e, "date_from")} />
              </div>

              <div className={"form-group"}>
                <label>Date fin</label>
                <input type="date" className={"form-control"} onChange={e => this.updateFilter(e, "date_to")} />
              </div>

              <div className={"form-group"}>
                <label>Zones</label>
                <input
                  name="site"
                  className={"form-control"}
                  placeholder="Nom ou code site"
                  value={this.state.filters.site}
                  onChange={e => this.updateFilter(e, "site")}
                />
              </div>

              <div className={"form-group"}>
                <label>ID SSR</label>
                <input
                  type={"text"}
                  name="pm"
                  className={"form-control"}
                  onKeyUp={e => this.updateFilter(e, "pm")}
                  defaultValue={this.state.filters.pm}
                />
              </div>
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> SSR trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <TaskList
              data={this.state}
              rows={this.state.rows}
              handlers={this.handlers}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const TaskListConsumer = props => (
  <SocketContext.Consumer>{socket => <TaskListContainer {...props} socket={socket} />}</SocketContext.Consumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(TaskListConsumer);
