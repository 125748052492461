import { combineReducers } from "redux";
import Filters from "./Filters";
import UI from "./UI";
import Session from "./Session";
import NotificationsReducer from "./NotificationsReducer";

let initialState;


initialState = {
	  taskList: [],
  agentsList: [],
};

const userReducer = function(state = initialState, action) {
  
  if (action.type === "UPDATE_DATA") {
    let newState = {
      ...state,
      agentsList: action.payload.agents,
    };

    return newState;
  }

  return state;
};

export default combineReducers({ filters: Filters, userReducer, ui: UI, session: Session, notifications: NotificationsReducer });
