import React from "react";
import GPMAnomalies from "./GPMAnomalies";
import GPMChecklist from "./GPMChecklist";


import GPMSpareParts from "./GPMSpareParts";
import GPMAssets from "./GPMAssets";
import GPMPhotos from "./GPMPhotos";

const GPMTests = [
    // {
    //     name: "Clockin",
    //     key: "gpm_clockin",
    //     component: null,
    // },
    {
        name: "Assets",
        key: "gpm_assets",
        component: GPMAssets
    },
    {
        name: "Checklist",
        key: "gpm_checklist",
        component: GPMChecklist
    },
    {
        name: "Spare Parts",
        key: "gpm_spareparts",
        component: GPMSpareParts
    },
    {
        name: "Anomalies",
        key: "gpm_anomalies",
        component: GPMAnomalies,
    },
    {
        name: "Photos",
        key: "gpm_photos",
        component: GPMPhotos
    }
];

export default GPMTests;