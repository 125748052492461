import React from "react";
import AnomalieRow from "./AnomaliesRow";
import { Trans } from "react-i18next";
import NOKRow from "./NOKRow";
import api from "../../Utils/Api";
import Pagination from "../../Utils/Pagination";
import Select from 'react-select';

class NOKList extends React.Component {


    constructor(props) {
        
        super(props)

        this.state = {
            data: [],
            total: 0,
            current: 1,
			pages: 1,
			limit: 15,
        }
    }

    componentDidMount() {
        this.props.isLoading();
        this.loadAnomalies(1)
    }

    loadAnomalies = (p = this.state.current) => {


        let crits = "limit="+this.state.limit+"&"+this.props.buildFilters();
        

		api.get('/api/anomalies/nok/fetch?page='+p+'&'+crits)
		.then((response) => {
			if(response.data.success) {

				this.setState({
					data: response.data.data, 
					pages: response.data.pages, 
					total: response.data.total
				})
            }
            

			this.props.doneLoading();

		});
    }

    changeLimit = (e) => {

        
        this.props.isLoading();
        
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAnomalies())

	}

    selectPage = (p) => {

		this.props.isLoading();
		this.setState({current: p});
		this.loadAnomalies(p);
    }
    
    render() {


        return (

            <>

            <div className={"row mb-3"}>
                <div className={"col-md-6 items-found"}>
                    <p>
                        <b>{this.state.total}</b> anomalies trouvés
                    </p>

                    
                </div>
                <div className={"col-md-6 per-page-wrap"}>
                    <div className={"per-page-content"}>
                        <p>Résultat par page</p> 
                        <Select
                            classNamePrefix={"custom-select"}
                            onChange={this.changeLimit}
                            className={"per-page nm"}
                            options={this.props.filters.result_per_page}
                            value={{label: this.state.limit, value: this.state.limit}}
                        />
                    </div>
                    <Pagination
                        data={this.state}
                        handleClick={this.selectPage} 
                    />
                    
                </div>

            </div>
            
            <div>
                <div className="col-md-12 ctable mb-3">
                
                
                    <div className={"d-none d-sm-block"}>
                        <div className={"row head-titles ctable-row"}>


                            <div className={"col-md-3"}>
                                Type
                            </div>

                            <div className={"col-md-1"}>
                                Status
                            </div>
                            
                            <div className={"col-md-3"}>
                                Check
                            </div>

                            <div className={"col-md-2"}>
                                Commentaire
                            </div>
                            
                            <div className={"col-md-2"}>
                                PM
                            </div>
                            

                            <div className={"col-md-1 task-details-col"}>
                                <Trans i18nKey="label_details" />
                            </div>

                        </div>
                    </div>

                    {this.state.data.map((item, key) => (
                        <NOKRow
                            selectMode={this.state.selectMode}
                            key={key}
                            data={item}
                            selected={this.props.rowProps.selected_list_nok.indexOf(item.id) > -1}
                            {...this.props.rowProps}
                        />
                    ))}


                    


                </div>
                
            </div>
            </>
        )
    }
}
export default NOKList;