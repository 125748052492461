import React from "react";
import ApiService, { loginCall } from "../../Utils/ApiService"
import store from "../store";
export function addArticle(payload) {
  return { type: "ADD_ARTICLE", payload }
};

export function startLoading(payload) {
  return { type: "IS_LOADING", payload }
};

export function doneLoading(payload) {
  return { type: "DONE_LOADING", payload }
};

export function setAuthAction(payload) {

  return function(dispatch) {

    dispatch({ type: "SET_AUTH", payload });

    ApiService.fetchFilters().then(res => {


      if(res.data.sticky)
        dispatch(updateSticky(res.data.sticky))

      return dispatch(updateFilters(res.data));
    })

  }
};

export function setOverlay(payload) {
  return { type: "SET_OVERLAY", payload }
};

export function updateNotifications(payload) {
  return { type: "UPDATE_NOTIFICATIONS", payload }
};

export function readNotifications(payload) {
  return { type: "READ_NOTIFICATIONS", payload }
};

export function setFlash(payload) {
  return { type: "SET_FLASH", payload }
};

export function closeFlash(payload) {
  return { type: "CLOSE_FLASH", payload }
};

export function appLoaded(payload) {
  return { type: "APP_LOADED", payload }
};

export function updateData(payload) {
  return { type: "UPDATE_DATA", payload }
};

export function updateFilters(payload) {
  return { type: "SET_FILTERS", payload }
};

export function updateSticky(payload) {
  return { type: "UPDATE_STICKY", payload }
};

// export function fetchFilters() {

//   ApiService.fetchFilters().then(res => {
//     store.dispatch(updateFilters(res.data));
//   })
// }

export function loginUser(data) {



  store.dispatch(closeFlash());

  store.dispatch(startLoading({
    message:'Connexion en cours'
  }))

  ApiService.loginCall(data).then(res => {
    store.dispatch(doneLoading())
    if(res.data.success) {
      store.dispatch(setAuthAction(res.data));
    } else {


      if(res.data.errcode === 110) {
        store.dispatch(setFlash({
          active: true,
          message: (<>Vous n'êtes pas autorisé à accéder à la platforme web, <a target='_blank' href='https://play.google.com/store/apps/details?id=com.witrack.rollout'>Veuillez télécharger l'application mobile ici</a></>),
          classes: "warning",
          style: {}
        }))
      } else {
        store.dispatch(setFlash({
          active: true,
          message: res.data.error,
          classes: "warning",
          style: {}
        }))
      }

    }

  })

}
