import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave, faInfoCircle, faMap } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import { connect } from "react-redux";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import Restricted from "../../../Utils/Restricted";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import CoSiteRowDetails from "./CoSiteRowDetails";
import CoSiteRowDetailstest from "../../../Containers/CoSiteRowDetailstest";
import CoSiteRowDetailsTest2 from "./CoSiteMap";
import { Modal } from "react-bootstrap";

let kmlparse = null
class CoSiteRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: '#7bfc03',
      data: null, 
      zoom: null,
      center: null, 
      image: null,
    }
    //console.log('hey there')
    // this.setColor = this.setColor.bind(this);
    this.setData= this.setData.bind(this)
  }

  handleEscape = e => {
    if (e.keyCode === 27) {
      this.setState({ editMode: false });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true
    document.addEventListener("keyup", this.handleEscape);
    api
    .get("/api/sites/filters")
    .then((response) => {
      if (response.data.success) {
        
        if(this._isMounted)
          this.setState({ agents: response.data.agents });

      }
    });
    api.get(`/api/cosite/getfile/${this.props.item.id}`).then(res => {
      if(res.data){
            const parser = new DOMParser();
            let kml = parser.parseFromString(res.data.file, "text/xml");
            if(this._isMounted)
              this.setState({
                file : kml
              })
      }
    });
  }

  deleteCoSite = id => {
    this.props.setLoading(true);

    api
      .post("/api/sites/cosites/" + this.props.item.id + "/delete")
      .then(res => {
        if (res.data.success) this.props.loadCoSites();
      })
      .catch(err => {
        this.props.setLoading(false);
      });
  };

  editCoSite = id => {
    this.setState({ editMode: true });
  };

  submitEdiSite = e => {
    e.preventDefault();

    const data = formToObject(e.target);

    this.setState({ editMode: false });
    this.props.setLoading(true);

    api.post(`/api/sites/cosites/${this.props.item.id}/edit`, data).then(res => {
      this.props.loadCoSites();
    });
  };

  setData = (data , zoom, center,image) => {
    console.log('data')
    this.setState({
      data: data,
      zoom: zoom,
      center: center,
      image:image
    })
  }

  sendData = () => {
    if(!this.state.data){
      return
    }
    // let data = {
    //     "type":"FeatureCollection",
    //     "features":this.state.data
    // }
    let data = {
      layer: this.state.data,
      zoom: this.state.zoom,
      center: this.state.center,
      image: this.state.image
    }
    api.post('/api/cosite/setData/'+this.props.item.id, data).then(res =>  {
        if(res.data){
            console.log('res'+res.data.req)
            this.setState({show: false})
        }
    })
  }

 

  // loadKML = () => {
  //   //let that = this
  //   api.get(`/api/cosite/getfile/${this.props.item.id}`).then(res => {
  //     if(res.data){
  //           const parser = new DOMParser();
  //           let kml = parser.parseFromString(res.data.file, "text/xml");
  //           this.setState({
  //             file : kml
  //           })
  //     }
  //   });
  // }

  // setColor = (color) => {
  //   console.log('this this ')
  //   this.setState({
  //     color: color.hex
  //   })
  // }

  render() {
    const item = this.props.item;

    if (this.state.editMode) {
      return (
        <form onSubmit={this.submitEdiSite}>
          <div className={"row ctable-row"}>
            <div className={"col-md-2"}>
              <Select
                className={"col-md-12"}
                defaultValue={{ label: item.site_type, value: item.site_type }}
                options={this.props.filters.cosite_types}
                placeholder={"Type..."}
                classNamePrefix={"custom-select"}
                name={"site_type"}
              />
            </div>
            <div className={"col-md-2"}>
              <input
                name={"site_code"}
                defaultValue={item.site_code}
                placeholder={"Code eg. 4A16X002"}
                type="text"
                className={"form-control"}
              />
            </div>
            <div className={"col-md-2"}>
              <input
                name={"site_name"}
                defaultValue={item.site_name}
                placeholder={"Name eg. CMP Ben"}
                type="text"
                className={"form-control"}
              />
            </div>
            <div className={"col-md-2"}>
              <Select
                className={"col-md-12"}
                defaultValue={{ label: item.site_type_tec, value: item.site_type_tec }}
                options={this.props.filters.cosite_type_tec}
                placeholder={"Type..."}
                classNamePrefix={"custom-select"}
                name={"site_type_tec"}
              />
            </div>
            <div className={"col-md-2"}>
                <Select
                  className={"col-md-12"}
                  options={this.state.agents}
                  placeholder={"Agents"}
                  classNamePrefix={"custom-select"}
                  name={"site_agent"}
                  defaultValue={{ label: item.site_agent, value: item.site_agent_id }}
                />
            </div>
            
            <div className={"col-md-1"}>
              <button type="submit" className={"task-action-btn"}>
                Sauvegarder <FontAwesomeIcon icon={faSave} />{" "}
              </button>
            </div>
          </div>
        </form>
      );
    } else
      return (
        <div>
          <div className={"row ctable-row hoverable"}>
            <div className={"col-md-2"}>{item.site_type}</div>
            <div className={"col-md-2"}>{item.site_code}</div>
            <div className={"col-md-2"}>{item.site_name}</div>
            <div className={"col-md-2"}>{item.site_type_tec}</div>
            <div className={"col-md-2"}>{item.site_agent}</div>
            <div className={"col-md-4 showOnHover"}>
              <Restricted scope={"pm"} permission={"edit_site"}>
                <button type="button" onClick={() => this.deleteCoSite(item.id)} className={"task-action-btn danger"}>
                  Supprimer <FontAwesomeIcon icon={faTrash} />{" "}
                </button>
                <button type="button" onClick={() => this.editCoSite(item.id)} className={"task-action-btn"}>
                  Modifier <FontAwesomeIcon icon={faEdit} />{" "}
                </button>
                {/* <button type="button" onClick={() => this.props.planCM(item)} className={"task-action-btn"}>
                  New CM
                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                </button> */}
                <button type="button" onClick={() => this.setState({show: !this.state.show})} className={"task-action-btn"}>
                  Map
                  <FontAwesomeIcon icon={faMap} />{" "}
                </button>
              </Restricted>
            </div>
          </div>

          <div className={"ui-overlay" + (this.state.show ? " open" : "")}>
            <div className={"ui-overlay-content anomalie-history col-md-7"}>
              <CoSiteRowDetails item={this.props.item} file={null} setData ={this.setData}/>
              <div className="mb-2"></div>
              <button className="btn btn-danger" onClick={() => this.setState({show:false})} >Annuler</button>
             { this.state.data ? <button className="btn btn-success" onClick={() => this.sendData()}>Sauvegarder</button>
              : 
              <button className="btn btn-success" type="button" disabled>Sauveggarder</button>
             }
            </div>
          </div>
          {/* <Modal show={this.state.show} onHide={() => this.setState({show:false})} centered>
            <Modal.Header closeButton >
              <Modal.Title>{item.site_code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CoSiteRowDetails item={this.props.item} file={null} setData ={this.setData}/>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger">Annuler</button>
             { this.state.data ? <button className="btn btn-success" onClick={() => this.sendData()}>Sauvegarder</button>
              : 
              <button className="btn btn-success" type="button" disabled>Sauveggarder</button>
             }
            </Modal.Footer>
          </Modal> */}
          {/* {this.state.show && <div className={"row ctable-row hoverable"}> */}
            {/* <CoSiteRowDetails item={this.props.item} file={null} /> */}
            {/* <CoSiteRowDetailsTest2 
                  id ={this.props.item} 
                  file={ this.state.file} 
                  color={this.state.color}
                 // setColor={this.setColor}
            /> */}
          {/* </div>} */}
        </div>
      );
  }
}

export default connect(state => ({ filters: state.filters }))(CoSiteRow);
