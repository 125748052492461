import React  , { Component }from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave, faInfoCircle, faSpinner, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import api from "../Utils/Api";
import { connect } from "react-redux";
import Select from "react-select";
import { formToObject } from "../Utils/Helpers";
import Restricted from "../Utils/Restricted";
import { MapContainer, TileLayer, Marker, Popup , GeoJSON , FeatureGroup} from 'react-leaflet';
// import { EditControl } from "react-leaflet-draw"
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/images/marker-shadow.png";
import { circle, Icon } from "leaflet";
import ReactLeafletKml from 'react-leaflet-kml';
import rewind from "@mapbox/geojson-rewind";
import * as tj from "@mapbox/togeojson";
import Constants from "../Utils/Constants";
import { CirclePicker, SketchPicker , TwitterPicker} from 'react-color';
import { setAuthAction, startLoading, doneLoading } from "../Redux/actions/index";

// import "leaflet-draw/dist/leaflet.draw.css"

//const parser = new DOMParser();

const mapStateToProps = state => {
  return {
    session: state.session,
    filters: state.filters
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e))
  };
}

class CoSiteRowDetailstest extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      file:null,
      color:'#7bfc03',
      
      //parser: new DOMParser()
    }

    this.submitUploadMap = this.submitUploadMap.bind(this);
    //this.setColor = this.setColor.bind(this)


    // if(this.props.item.site_file){
    //   console.log('here' +this.props.item.site_file)
    //   const parser = new DOMParser();
    //   let kml = parser.parseFromString(this.props.item.site_file, "text/xml");
    //   this.setState({
    //     file: kml
    //   })
    // }
    

  }
  setColor = (color) => {
    console.log(this.state.file)
    if(this.state.color !== ''){
      this.setState({
        color: color
      })
    }

  }

  // handleEscape = e => {
  //   if (e.keyCode === 27) {
  //     this.setState({ editMode: false });
  //   }
  // };

  // componentWillUnmount() {
  //   document.removeEventListener("keyup", this.handleEscape);
  //   this._isMounted = false;
  // }

  componentDidMount() {
    this.loadKML()
  }

  // deleteCoSite = id => {
  //   this.props.setLoading(true);

  //   api
  //     .post("/api/sites/cosites/" + this.props.item.id + "/delete")
  //     .then(res => {
  //       if (res.data.success) this.props.loadCoSites();
  //     })
  //     .catch(err => {
  //       this.props.setLoading(false);
  //     });
  // };

  // editCoSite = id => {
  //   this.setState({ editMode: true });
  // };

 
  loadKML = () => {
    let that = this
    const parser = new DOMParser();
    api.get(`/api/cosite/getfile/${this.props.item.id}`).then(res => {
      if(res.data){
        // this.handleFileSelection2(res.data.file);
       // console.log('file => '+ res.data.file)

        let kml = parser.parseFromString(res.data.file, "text/xml");
       
        //parser = null
        this.setState({
          file:null
        })
        console.log('see kml parser '+ kml)
        this.setState({
          file: kml
        })
      }
    });

  }

  submitUploadMap = (e) =>{
    
    e.preventDefault();
    // this.setState({
    //   loading: true
    // })
    // const parser = new DOMParser();
    const data = new FormData(e.target);
    //console.log('daya => '+ document.getElementById("sites_file").files[0])

    //commented cuz I dont use geojson and it cuz me an error
    // var reader = new FileReader();
    // let file  = document.getElementById("sites_file").files[0]
    // reader.readAsText(file);
    //this.handleFileSelection2(file)
        api.post(e.target.action+'/'+ this.props.item.id, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
          //this.loadKML()
          //console.log('kjbr')
          //this.loadKML()
            // if(res.data.error)  
            //     this.setState({ error: res.data.error });
            
            // if(res.data.success)   
            //     this.setState({ success: success || "Opération effectué avec succés"});

            // if(res.data.notfound)  
            //     this.setState({notImported: res.data.notfound });

            // this.setState({loading: false, file: null});

            
            // console.log(res.data)
        })
       
    // this.setState({
    //   loading: false
    // })
    // let file  = document.getElementById("sites_file").files[0]
    // let kml = parser.parseFromString(file, "text/xml");
    // this.setState({
    //   file: kml
    // })
    
  }


  handleFileSelection = (event) => {
    const file = event.target.files[0]; // get file
   // const file = event.target
    console.log(file);
    const ext = this.getFileExtension(file);
    const reader = new FileReader();

    // on load file end, parse the text read
    reader.onloadend = (event) => {
      var text = event.target.result;
      if (ext === "kml") {
        this.parseTextAsKml(text);
      } else {
        // imported geojson
        const json = JSON.parse(text);
        rewind(json, false);
        console.log(json);
        // setLayer(json);
        this.setState({
          layer: json
        })
      }
    };

    reader.readAsText(file); // start reading file
  };

  handleFileSelection2 = (file) => {
  //   const file = event.target.files[0]; // get file
  //  // const file = event.target
  //   console.log(file);
    const ext = this.getFileExtension(file);
    const reader = new FileReader();

    // on load file end, parse the text read
    reader.onloadend = (event) => {
      var text = event.target.result;
      if (ext === "kml") {
        this.parseTextAsKml(text);
      } else {
        // imported geojson
        const json = JSON.parse(text);
        rewind(json, false);
        console.log(json);
        // setLayer(json);
        this.setState({
          layer: json
        })
      }
    };

    reader.readAsText(file); // start reading file
  };
  getFileExtension = (file) => {

    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    return name.substring(lastDot + 1);
  };
  parseTextAsKml = (text) => {
    const dom = new DOMParser().parseFromString(text, "text/xml"); // create xml dom object
    const converted = tj.kml(dom); // convert xml dom to geojson
    rewind(converted, false); // correct right hand rule
    console.log(converted);
    // setLayer(converted); // save converted geojson to hook state
    this.setState({
      layer: converted // save converted geojson to hook state
    })
  };


  hashString = (str) => {
    var hash = 0,
      i,
      chr;
    for (i = 0; i < Math.min(str.length, 255); i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  _onCreated = e  => { 
    console.log('create')
    // this.setState({
    //   color: '#FCB900'
    // })
  } 

  _onEdited = e  => { 
    console.log('edit')
  } 
  _onDeleted = e  => { 
    console.log('delete')
  } 

   
   
  render() {
    const item = this.props.item;

      return (
        <React.Fragment>
          <div style={{height: '220px', width: '100%' }} className={'row'}>
            <div className={"col-md-6 map"}>
              
              <MapContainer center={[36.000, 2.09]} zoom={5} scrollWheelZoom={true} className="map"  style={{height: '180px'}}>
                    
                    <FeatureGroup>
                        <EditControl 
                            position="topright" 
                            onCreated={this._onCreated}
                            onEdited={this._onEdited}
                            onDeleted={this._onDeleted}
                            draw={{
                              rectangle: false,
                              polyline:false,
                              polygon: {
                                shapeOptions: {
                                    color:['#7bfc03'],
                                    weight: 2
                                }
                              },
                              circle:false,
                              // circlemarker:false,
                              marker:false,
                            }}
                            
                            edit={{
                              selectedPathOptions: {
                                  maintainColor: false,
                                  color: this.state.color,
                                  fillColor: this.state.color,
                                  //opacity: 0.3
                              },
                              shapeOptions: { color: '#7bfc03' },

                            }}
                        />
                    </FeatureGroup>

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                     {this.state.file &&  <ReactLeafletKml kml={this.state.file} />}
                  
                      {/* {this.props.file &&  <ReactLeafletKml kml={this.props.file} />} */}
                    {/* <Marker position={[36.000, 2.09]}>
                       
                    </Marker> */}
                    {/* {this.state.layer && (
                      <GeoJSON key={this.hashString(JSON.stringify(this.state.layer))} data={this.state.layer} />
                    )} */}
              </MapContainer>
            </div>
            <div className={"col-md-6"}>
                {/* <input type="file" onChange={this.handleFileSelection} /> */}
               

               <form onSubmit={this.submitUploadMap}  action={Constants.apiURL+"/api/cosite/file"} method="post">
                <div className="row">
                  <div className="col-md-9">
                    <input type="file" name="sites_file" id="sites_file" className={"form-control"}  accept=".kml, .xml"/>
                  </div>
                  <div className="col-md-1">
                    <button type="submit" className={"btn btn-success p-2 "}>
                      Envoyer
                    </button>
                  </div>
                </div>

              </form>
              <div className="row">
                  <TwitterPicker colors={['#FF6900', '#FCB900']} onChange={ (e) => this.setColor('#FCB900')}/>
                </div>
            </div>
          </div>
          </React.Fragment>
      );
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoSiteRowDetailstest);
