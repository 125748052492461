import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const EditSiteOverlay = ({site, close, loadSites, ...props}) => {
  

  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    props.isLoading({
      message: "Mise à jour du site",
      animate: true,
    });

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;

    api
      .post("/api/sites/edit", data)

      .then((response) => {
        props.doneLoading();
        if (response.data.success) {
          close();
          //window.ga('send', 'event', 'task', 'add'); // Google Analytics
          props.setOverlay({
            message: "Site modifié avec succés",
            label: "success",
            button: {
              type: "close",
              label: "OK",
              link: "javascript:void(0)",
            },
          });
        } else {
          if (response.data.errcode === 101) {
            props.setAuth(response.data);
          }
        }

        
      })
      .catch((err) => props.doneLoading());
  };

    return (
      <div className={"ui-overlay " + (props.open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-3"}>
          <button
            data-package-id="0"
            onClick={(e) => {
              close(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>Fiche du site</h5>

          <form onSubmit={submitSiteForm}>
            {props.open && (
              <>
                <input
                  type="hidden"
                  name="site_id"
                  value={site.site_id}
                />

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code site</label>
                  <input
                    type="text"
                    name="site_code"
                    className={"form-control col-md-8"}
                    defaultValue={site.site_code}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Nom site</label>
                  <input
                    type="text"
                    name="site_name"
                    className={"form-control col-md-8"}
                    defaultValue={site.site_name}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <label className={"font-exp"}>Wilaya</label>
                  <Select
                    name="site_wilaya"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: site.site_address_wilaya,
                      label: site.site_address_wilaya,
                    }}
                    options={props.filters.wilayas}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <label className={"font-exp"}>Status</label>
                  <Select
                    name="site_status"
                    classNamePrefix={"custom-select"}
                    defaultValue={props.filters.site_status.filter(item => item.value === site.site_status)}
                    options={props.filters.site_status.filter(item => item.value !== "all")}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form mt-3 no-gutters">
                  <label className={"font-exp"}>Remarque</label>
                  <textarea
                    rows={5}
                    name="note"
                    className={"form-control"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Valider les modifications"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  
}

export default EditSiteOverlay;
