import React from 'react';
import api from '../../../Utils/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Select from "react-select";
import { connect } from 'react-redux';
import Constants from '../../../Utils/Constants';


const mapState = state => ({filters: state.filters});
class Notifications extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: 'someValue',
      sticky: "",
      saving: false,
      sending: false,
      tab: "users"
    };
  }


  componentDidMount() {
      api.get('/api/settings/notifications')
      .then(res => {
          if(res.data.success)
            this.setState({sticky: res.data.sticky})


      })
  }

  submit = e => {
    this.setState({sending: true});
    e.preventDefault();
    const data = new FormData(e.target);
    api.post(e.target.action, data)
    .then(res => {
        this.setState({sending: false});
    })

  }

  submitSticky = e => {
    this.setState({saving: true});
    e.preventDefault();
    const data = new FormData(e.target);
    api.post(e.target.action, data)
    .then(res => {
        this.setState({saving: false});
    })
  }

  render() {
    return (
        <>
            <h3>Notifications & announcement</h3>
            <div className={"row mt-5"}>
                <div className={"form-group col-md-6"}>
                    <h4>Notification mobile ciblé</h4>

                    <div className={"tab-list"}>
                        {/* <span className={this.state.tab === "filters" && "active"} onClick={() => this.setState({tab: "filters"})}>Filtres</span> */}
                        <span className={this.state.tab === "users" && "active"} onClick={() => this.setState({tab: "users"})}>Utilisateurs</span>
                    </div>

                    <form onSubmit={this.submit} action={Constants.apiURL+"/api/settings/notifications/publish"}>


                        <div className={"tab-container"}>

                            {this.state.tab === "filters" && <div>
                                <div className={"form-group"}>
                                    <label>Cluster</label>
                                    <Select
                                        name={"cluster[]"}
                                        classNamePrefix={"custom-select"}
                                        options={this.props.filters.clusters}
                                        isMulti={true}
                                    />
                                </div>

                                <div className={"form-group"}>
                                    <label>Owner</label>
                                    <Select
                                        name={"owner[]"}
                                        classNamePrefix={"custom-select"}
                                        options={this.props.filters.site_owners}
                                        isMulti={true}
                                    />
                                </div>
                            </div>}

                            {this.state.tab === "users" && <div>
                                <div className={"form-group"}>
                                    <label>Agent</label>
                                    <Select
                                        name={"users[]"}
                                        classNamePrefix={"custom-select"}
                                        options={this.props.filters.agents}
                                        isMulti={true}
                                    />
                                </div>
                            </div>}


                        </div>


                        <div className={"form-group"}>
                            <label>Message</label>
                            <textarea name={"message"} className={"form-control"}></textarea>
                        </div>

                        <button className={"btn btn-primary btn-sm mt-1 float-right"}>
                            Envoyer
                            <FontAwesomeIcon className={"ml-2"} spin={this.state.sending} icon={this.state.sending ? faSpinner : faCheck} />
                        </button>
                    </form>

                </div>

                <div className={"form-group col-md-6"}>
                    <form onSubmit={this.submitSticky} action={Constants.apiURL+"/api/settings/notifications/sticky"}>

                        <h4>Message épinglé</h4>
                        <div>
                            <label>Message</label>
                            <input defaultValue={this.state.sticky} name={"message"} className={"form-control p-1"} />
                            <button className={"btn btn-primary btn-sm mt-3 float-right"}>
                                Valider
                                <FontAwesomeIcon className={"ml-2 "} spin={this.state.saving} icon={this.state.saving ? faSpinner : faCheck} />
                            </button>
                        </div>
                    </form>
                </div>


            </div>

        </>
    );
  }


}

export default connect(mapState)(Notifications);
