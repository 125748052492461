import React, { useState, useRef } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../Utils/Api";
import ApiService from "../../../Utils/ApiService";


const RejectOverlay = ({toggle, open, task, reload, scope = "pm", ...props}) => {


        const messageRef = useRef(null)

        const rejectTask = () => {

            props.isLoading({
                message: "Mise à jour en cours",
                animated: true
            });

            const data = {
                pmid : task,
                message: messageRef.current.value,
                scope
            }

            // ApiService.returnTask(data)
            // .then(response => {
            //     toggle();
            //     reload();
            //     props.doneLoading()
            // })
            ApiService.rejectTask(data)
            .then(response => {
                toggle();
                reload();
                props.doneLoading()
            })
            

        }
    
    
        return (
            <>
                <div className={open? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
                    <div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
                        <button 
                            onClick={e => toggle() } 
                            className={"ui-overlay-close btn btn-danger btn-sm"}>
                        <FontAwesomeIcon icon={faTimes} />
                        </button>

                        <h5 className={"mb-4"}>Vous pouvez inclure un message : </h5>

                        <textarea ref={messageRef} className={"form-control"} />
                        
                        <button onClick={rejectTask} className={"btn btn-primary mt-3"}>Valider</button>
                        
                    </div>
                </div>
            </>
        )
    

}

export default RejectOverlay;