import React, { useState, useRef , useEffect  } from "react";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../Utils/Api";
import ApiService from "../../../Utils/ApiService";
import Select from "react-select";
import { filters }  from "../../../Redux/reducers/Filters";

const eqp = [
    { label: "", value: "" },    
    { label: "ROOM", value: "ROOM" },    
    { label: "SHELTER", value: "SHELTER" },
    { label: "ROOM + OUTDOOR CABINET", value: "ROOM + OUTDOOR CABINET" },
    { label: "SHELTER + OUTDOOR CABINET", value: "SHELTER + OUTDOOR CABINET" },
	{ label: "OUTDOOR CABINET", value: "OUTDOOR CABINET" },
	{ label: "SMALL CELL", value: "SMALL CELL" },
]
var filter_update = filters.structure_type
const AddDataOverlay = ({toggle, open, task, reload, scope = "pm", data_m,  ...props}) => {

   

        const messageRef = useRef(null)

       
        
        //useEffect(() => {setTab(data_m) ; setFilter(filter_update)} )
        useEffect(() => { setTab(data_m)})
       
        const [tab, setTab] =  useState(data_m) 
        const [filter, setFilter] =  useState(filters.structure_type) 
        
        const addDataTask = () => {

            props.isLoading({
                message: "Mise à jour en cours",
                animated: true
            });

            const data = {
                pmid : task,
                data: tab
                // message: messageRef.current.value,
                // scope
            }

            // ApiService.returnTask(data)
            // .then(response => {
            //     toggle();
            //     reload();
            //     props.doneLoading()
            // })
            ApiService.addDataTask(data)
            .then(response => {
                toggle();
                reload();
                props.doneLoading()
            })
            
            

        }
        
        const add = () => {
            //tab.push('')
            if (tab.length > 1) return
            let array = tab 
            array.push(['', '',  '', '', ''])
            // setTab((prvtab) => [
            //     ...prvtab,
            //     ['','', '',  '', '', ''],
            // ]);
            setTab([...array])
            console.log('tab after add' + JSON.stringify(tab))
        }

        const remove = (index) => {
            var array = tab; 
            console.log('remove' + array)
            if (index !== -1) {
                array.splice(index, 1);
                setTab([...array]);
               // setTab(oldValue => oldValue.filter((_, i) => i !== index))
            }
        }

        const update = (index, sindex,e ) => {
            let newArr = [...tab]; // copying the old datas array
            const value = e.target ? e.target.value : e.value;
            newArr[index][sindex] = value; // replace e.target.value with whatever you want to change it to
            
            setTab(newArr);

            // if (sindex === 0 ){
            //     if (value === 'GREENFIELD'){
            //         //setFilter(filters.greebfield)
            //         filter_update = filters.greebfield
            //     }else if (value === 'ROOFTOP'){
            //         //setFilter(filters.rooftop)
            //         filter_update = filters.rooftop
            //     }else{
            //         //setFilter(filters.indoor)
            //         filter_update = filters.indoor
            //     }
            //     console.log('target => '+ JSON.stringify(filter_update))
            // }
        }
    
    
        return (
            <>
                <div className={open? "ui-overlay add-task open" : "ui-overlay add-task"}>
                    <div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
                        <button 
                            onClick={e => toggle() } 
                            className={"ui-overlay-close btn btn-danger btn-sm"}>
                        <FontAwesomeIcon icon={faTimes} />
                        </button>
                                
                            <div className="row d-flex justify-content-center">
                                {/* <div className='col-md-2 ' >Site type</div> */}
                                <div className='col-md-2 ' >Structure Type</div>
                                <div className='col-md-2 ' >Eqp hous type</div>
                                <div className='col-md-2 ' >Plot size</div>
                                <div className='col-md-2 ' >Mod cap A</div>
                                <div className='col-md-2 ' >Max # Modules</div>
                            </div>
                            {tab.map((items, index) => (
                                
                                <div className="row  d-flex justify-content-center">
                                    {items.map((sitems, sindex) => (
                                        
                                        <div className='col-md-2 p-0'>
                                            { 
                                            // sindex === 0 ? 
                                            //             <Select
                                            //                 options={filters.site_type}
                                            //                 //value={sitems}
                                            //                 onChange={(e) => update(index,sindex ,e)}
                                            //             />
                                            // :
                                            sindex === 0 ? 
                                                            <select  value={sitems} onChange={e => update(index,sindex ,e)}  style={{height: '3.4em' , width: '100%'}}>
                                                                {filter.map((itm) => (<option value={itm.label}>{itm.label}</option>))}
                                                            </select>
                                            : sindex === 1 ? 
                                            
                                                            <select  value={sitems} onChange={e => update(index,sindex ,e)}  style={{height: '3.4em' , width: '100%'}}>
                                                                {filters.eqp.map((itm) => (<option value={itm.label}>{itm.label}</option>))}
                                                            </select>
                                            
                                            : 
                                            <input className="col-md-12" type="text" value={sitems} onChange={e => update(index,sindex ,e)} style={{height: '3.4em'}}/>
                                            }
                                        </div>
                                    ))

                                    }
                                </div>
                                
                            ))}
                            <button onClick={add} className={"btn btn-primary mt-3"}><FontAwesomeIcon icon={faPlus} /> Ajouter</button>
                          
                        
                        
{/* 
                        <textarea ref={messageRef} className={"form-control"} /> */}
                        
                        <button onClick={addDataTask} className={"btn btn-primary mt-3"}>Envoyer</button>
                        
                    </div>
                </div>
            </>
        )
    
{/* <button 
    onClick={e => remove(sindex) } 
    className={"rounded-circle btn btn-sm"}>
<FontAwesomeIcon icon={faTimes} />
</button> */}
}

export default AddDataOverlay;