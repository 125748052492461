import React, { useState, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faTimes, faSpinner, faHourglass} from '@fortawesome/free-solid-svg-icons';
import MoreInfoDialog, { Item } from '../../../Utils/MoreInfoDialog';

import moment from 'moment';

import CMTests from './Tests';
import CheckOrTime from '../components/CheckOrTime';
import Restricted from '../../../Utils/Restricted';
import RenderComponent from '../../../Utils/RenderComponent';




const CMTaskRow = ({task, key, exportPM, deleteTask, returnTask, closeTask, ...props}) => {

    // const i = key;


    const [activeTest, selectTest] = useState("cm_clockin")
    const [showDetail, setShowDetail] = useState(false);


    return (<React.Fragment>
        <div className={"row "}>
        <div className={"col-md-12"}>

        <div className={"row ctable-row"}>


                <div className={"col-md-1 table-space-between"}>
                     <span>{task.task_id}</span>
                      {
                        !task.task_status
                        ?
                        <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} />
                        :
                        task.task_status === "return"
                        ?
                        <FontAwesomeIcon color="orange" icon={faUndo} />
                        :
                        task.task_status === "pending"
                        ?
                        <FontAwesomeIcon color="grey" icon={faHourglass} />
                        :
                        <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />}
                </div>


                <div className={"col-md-2"}>
                    <a className={"site-link"} href={"/agents/"+task.task_agent_id}>{task.task_agent}</a>
                </div>

                <div className={"col-md-2"}>
                    <a title={task.task_site_name} onClick={() => props.handlers.updateFilter({value: task.task_site_code}, 'site')} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>
                        {task.task_site_logique_code + ` (${task.task_site_logique_type})`}<br />
                        {task.task_site_code}
                    </a>
                </div>

                <div className={"col-md-1"}>
                    {task.owner}
                </div>

                <div className={"col-md-2"}>
                    <span className={"task-plan-col"}>
                        <span className={"line task-"+task.task_delay}>
                            <span className={"line-label"}>Créé</span>
                            <span className={"line-value"}>{task.task_created}</span>
                        </span>
                        <span className={"line"}>
                            <span className={"line-label"}>Déclaré</span>
                            <span className={"line-value"}>{task.task_plan}</span>
                        </span>
                    </span>

                </div>

                <div className={"col-md-2 task-tests-col"}>
                    <span>{Object.keys(task.task_tests).filter((el) => el !== "cm_clockin" && task.task_tests[el].data && task.task_tests[el].data.done).length+"/"+CMTests.length}</span>

                </div>

                <div className={"col-md-2 task-details-col"}>

                    {task.geo && task.geo.lat && (
                        <a href={"https://www.google.com/maps/place/"+task.geo.lat+","+task.geo.lng+"/@"+task.geo.lat+","+task.geo.lng+",15z"} target='_blank' className="task-action-btn" >
                            <FontAwesomeIcon icon={faMapMarker}  />Localisation
                        </a>
                    )}

                    <button onClick={() => setShowDetail(!showDetail)} className="task-action-btn" ><FontAwesomeIcon icon={faChevronDown}  /> Info</button>

                    {/* <button onClick={() => exportPM(task) }  className="task-action-btn" ><FontAwesomeIcon icon={faFileExport}  /> Export</button> */}

                    <MoreInfoDialog title={"more"}>
                        <Restricted scope={"gpm"} permission={"delete_task"}>
                            <Item onClick={() => deleteTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash}  /> Supprimer</Item>
                        </Restricted>
                        {/* <Item onClick={() => closeTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faLock}  />Cloturer</Item> */}
                        <Restricted scope={"gpm"} permission={"return_task"}>
                            <Item onClick={() => returnTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faUndo}  />Retourner</Item>
                        </Restricted>
                        {/* <Item onClick={() => exportPM(task, true)}><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}  />Export NOK</Item> */}
                    </MoreInfoDialog>

                </div>

        </div>

        {!!showDetail && (

        <div className={"animateRow row"}>

                <div className={"pm-wrapper col-md-12 row no-gutters"}>

                    <div className={"col-md-2 pm-sidebar"}>
                        <ul>
                            {CMTests.map(test => (
                                <li className={activeTest === test.key ? "active" : ""}>
                                    <a onClick={e => selectTest(test.key)}>
                                        <CheckOrTime task={task.task_tests[test.key]} />
                                        {test.name} <FontAwesomeIcon icon={faAngleRight} size={22} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={"col-md-10 pm-content"}>
                    {CMTests.map(test => task.task_tests[test.key] && activeTest === test.key && (
                        <div className={"pm-test-single"}>
                            <h5>{test.name} {task.task_tests[test.key].data.finished_at ? <b>{moment(task.task_tests[test.key].data.finished_at).format('YYYY-MM-DD HH:mm').toString()} <br /></b> : ""}</h5>
                            <RenderComponent component={test.component} data={task.task_tests[test.key].data} />
                        </div>
                    ))}

                    </div>


                </div>
        </div>
        )}

    </div>
    </div>
    </React.Fragment>

    )

}
export default CMTaskRow;
