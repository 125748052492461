import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";

const GPMAnomalies = ({ data, ...props }) => {
  return (
    <>
      {data.items &&
        data.items.map(item => (
          <div className={"single-anomalie"}>
            <div className={"row col-md-6"}>
              <label>Type Anomalie : </label>
              {item.type}
            </div>

            <div className={"row col-md-6"}>
              <label>Anomalie : </label>
              {item.label}
            </div>
          </div>
        ))}

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <PhotoItem photo={photo} active={true} />
          ))}
        </div>
      )}
    </>
  );
};

export default GPMAnomalies;
