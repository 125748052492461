import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoginBox from './Login/LoginBox';
import HomeView from './Home/HomeView';
import CommonRouter from '../Routers/CommonRouter';
import DeliveryRouter from '../Routers/DeliveryRouter';
import SSRRouter from '../Routers/SSRRouter';
import GPMRouter from '../Routers/GPMRouter';
import CMRouter from '../Routers/CMRouter';
import PlanningRouter from '../Routers/PlanningRouter';
import TasklistRouter from'../Routers/TasklistRouter';
import InstallationRouter from '../Routers/InstallationRouter';
import SitesListContainer from '../Containers/Delivery/SitesContainer';



const mapStateToProps = state => {
	return {
		session: state.session
	}
}
class AppRouter extends Component {

	render() {

		return(
			<div>
				<Switch>
					<Route exact path='/login' component={LoginBox} />
					<Route exact path='/' component={() => <HomeView auth={this.props.session.auth} />} />


					{this.props.session.auth && (
					<>

						{this.props.session.user.role !== "agent" && <CommonRouter />}
						<Route path='/delivery' component={DeliveryRouter} />
						<Route path='/ssr' component={SSRRouter} />
						<Route path='/gpm' component={GPMRouter} />
						<Route path='/cm' component={CMRouter} />
						<Route path='/Planning' component={PlanningRouter} />
						<Route path='/task_list' component={TasklistRouter} />
						<Route path='/installation' component={InstallationRouter} />
						<Route path='/zones' component={SitesListContainer} />


					</>
					)}

					<Route path='/' component={() => <HomeView auth={this.props.session.auth} />} />

				</Switch>
			</div>


		)
	}
}


export default withRouter(connect(mapStateToProps)(AppRouter));
