import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faEdit, faUndo, faUser, faCalendar, faCalendarDay, faRedoAlt, faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import Restricted from "../../../Utils/Restricted";


const SiteRowSSR = ({
  site,
  selectSite,
  editSite,
  reassignSite,
  planSite,
  role,
  session,
  ownerSite,
  ...props
}) => {

  return (
    <React.Fragment>
      {/*<tr>
        <td>{site.site_code}</td>
        <td>{site.site_name}</td>
        <td>{site.site_address_wilaya.toUpperCase()}</td>
        <td className={"site-plan-col-date"}>{role === "admin" || role === "cluster_manager" ? (
            <>
            <span>{site.site_owner}</span>
            <span>{site.site_manager_username}</span>
            </>
          ) : (
            <Link to={"/ssr/task/" + site.site_last_pm_id}>
              {site.site_last_pm_at}
            </Link>
          )}</td>
          <td>{site.site_next_pm}</td>
          <td>{site.site_owner_agent}</td>
          <td>
            <MoreInfoDialog title={"Actions"}>
              <Restricted scope={"pm"} permission={"read_site_detail"}>
                <Item>
                    <Link to={'/ssr/sites/detail/'+site.site_id}><FontAwesomeIcon icon={faFileInvoice} className={"mr-2"}  /> Détail</Link>
                </Item>
              </Restricted>
              <Restricted scope={"pm"} permission={"read_site_history"}>
                <Item onClick={(e) => selectSite(site)}>
                  <FontAwesomeIcon icon={faHistory} className={"mr-2"}  /> Historique
                </Item>
              </Restricted>
              <Restricted scope={"pm"} permission={"edit_agent_site"}>
                <Item onClick={(e) => reassignSite(site, e)}>
                    <FontAwesomeIcon icon={faUser} className={"mr-2"}  /> Attribuer à un agent
                </Item>
              </Restricted>
              {(session.role === "admin" || session.role === "cluster_manager" || (session.role === "manager" && session.owner === "O&M")) && (
                <>
                  <Restricted scope={"pm"} permission={"edit_site"}>
                    <Item onClick={(e) => editSite(site, e)}>
                        <FontAwesomeIcon icon={faEdit} className={"mr-2"}  /> Modifier
                    </Item>
                  </Restricted>
                  <Restricted scope={"pm"} permission={"edit_plan_site"}>
                    <Item onClick={(e) => planSite(site, e)}>
                        <FontAwesomeIcon icon={faCalendarDay} className={"mr-2"}  /> Planifier PM
                    </Item>
                  </Restricted>
                  <Restricted scope={"pm"} permission={"edit_owner_site"}>
                    <Item onClick={(e) => ownerSite(site, e)}>
                        <FontAwesomeIcon icon={faRedoAlt} className={"mr-2"}  /> Change Owner
                    </Item>
                  </Restricted>
                </>
              )}
            </MoreInfoDialog>
          </td>
      </tr>*/}

       <div className={"row ctable-row"}>
        <div className={"col-md-1"}>
          <Link className={"task-action-btn"} to={'/ssr/sites/detail/'+site.site_id}>{site.site_code}</Link>
        </div>

        <div className={"col-md-2"}>
          <Link className={"task-action-btn"} to={'/ssr/sites/detail/'+site.site_id}>{site.site_name}</Link>
        </div>

        <div className={"col-md-1"}>{site.site_address_wilaya.toUpperCase()}</div>

        <div className={"col-md-2  site-plan-col-date"}>
          {role === "admin" || role === "cluster_manager" ? (
            <>
            <span>{site.site_owner}</span>
            <span>{site.site_manager_username}</span>
            </>
          ) : (
            <Link to={"/ssr/task/" + site.site_last_pm_id}>{site.site_last_pm_at}</Link>
          )}
        </div>

        <div className={"col-md-1"}>{site.site_type}</div>

        <div className={"col-md-2 site-plan-col-date"}>
          <span>{site.site_next_pm}</span>
          <span>{site.site_next_pm_agent}</span>
        </div>

        <div className={"col-md-1"}>
          <span>{site.site_owner_agent}</span>
        </div>

        <div className={"col-md-2 seperate task-details-col"}>
          <span className={"site-plan-col-action"}>

            <MoreInfoDialog title={"Actions"}>

              <Restricted scope={"pm"} permission={"read_site_detail"}>
                <Item>
                    <Link  className={"d-block"} to={'/ssr/sites/detail/'+site.site_id}><FontAwesomeIcon icon={faFileInvoice} className={"mr-2"}  />Détail</Link>
                </Item>
              </Restricted>

              <Restricted scope={"pm"} permission={"read_site_history"}>
                <Item onClick={(e) => selectSite(site)}>
                  <FontAwesomeIcon icon={faHistory} className={"mr-2"}  /> Historique
                </Item>
              </Restricted>

              <Restricted scope={"pm"} permission={"edit_agent_site"}>
                <Item onClick={(e) => reassignSite(site, e)}>
                    <FontAwesomeIcon icon={faUser} className={"mr-2"}  /> Attribuer à un agent
                </Item>
              </Restricted>

              {(session.role === "admin" || session.role === "cluster_manager" || (session.role === "manager" && session.owner === "O&M")) && (
                <>
              <Restricted scope={"pm"} permission={"edit_site"}>
                <Item onClick={(e) => editSite(site, e)}>
                    <FontAwesomeIcon icon={faEdit} className={"mr-2"}  /> Modifier
                </Item>
              </Restricted>

              <Restricted scope={"pm"} permission={"edit_plan_site"}>
                <Item onClick={(e) => planSite(site, e)}>
                    <FontAwesomeIcon icon={faCalendarDay} className={"mr-2"}  /> Planifier SSR
                </Item>
              </Restricted>

              <Restricted scope={"pm"} permission={"edit_owner_site"}>
                <Item onClick={(e) => ownerSite(site, e)}>
                    <FontAwesomeIcon icon={faRedoAlt} className={"mr-2"}  /> Change Owner
                </Item>
              </Restricted>
              </>
            )}

            </MoreInfoDialog>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SiteRowSSR;
