import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./lib/Components/Header";
import Body from "./lib/Components/Body";
import Footer from "./lib/Components/Footer";
import AppRouter from "./lib/Components/Router";
import openSocket from "socket.io-client";
import { connect } from "react-redux";
import SocketContext from "./lib/Utils/socket-context";

import getNotifications from "./lib/Utils/getNotifications.js";

import {
  updateData,
  updateNotifications,
  setAuthAction,
  doneLoading,
  startLoading,
  setOverlay,
  updateFilters,
  appLoaded,
  setFlash,
} from "./lib/Redux/actions/index";
import "./style.scss";
import "./lib/i18n/index";
import Constants from "./lib/Utils/Constants";
import LoadingOverlay from "./lib/Components/Common/LoadingOverlay";
import MessageOverlay from "./lib/Components/Common/MessageOverlay";
import Notifications from "./lib/Components/Common/Notifications";

var io;

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    ui: state.ui,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setFlash: p => dispatch(setFlash(p)),
	  appLoaded: () => dispatch(appLoaded()),
    doneLoading: (user) => dispatch(doneLoading()),
    setOverlay: (e) => dispatch(setOverlay(e)),
    updateNotifications: (e) => dispatch(updateNotifications(e)),
    setAuth: (e) => dispatch(setAuthAction(e)),
    updateData: (e) => dispatch(updateData(e)),
    updateFilters: (x) => dispatch(updateFilters(x)),
  };
}

class App extends Component {
  constructor(props) {
    super(props);
  }



  componentDidMount() {
    const { user, auth } = localStorage.user_session
      ? JSON.parse(localStorage.user_session)
      : { user: {}, auth: false };

    console.log(user, auth);
    if (auth) {
	    this.props.setAuth({ ...user, success: true });
    }
	  this.props.appLoaded();


    if(auth) {
      if(!user.permissions) {
        console.log("setflash");
        this.props.setFlash({
          active: true,
          classes: "warning",
          message: "Veuillez vous reconnecter."
        });
      }


    }

    // if(this.props.auth) {
    // 	ApiService.fetchFilters().then(res => {
    // 		this.props.updateFilters(res.data);
    // 	})
    // }

    // 	const that = this;

    // 	api.get('/api/form/filters')
    // 	.then(function(response) {
    // 		if(response.data.success) {
    // 			that.props.updateFilters(response.data)
    // 		}
    // 	});
    // }

    //this.notificationsPoll();

    // if(this.props.auth) {
    // 	let remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://salim.tictechs.tech';
    // 	io = openSocket(remote+':8800', {
    // 		secure: true,
    // 		query: {
    // 			token: this.props.user.token,
    // 			userid: this.props.user.userid
    // 		}
    // 	});//, {transports: ['websocket'], upgrade: false});

    // 	this.getNotifications();
    // }
    //_.debounce(getNotifications.bind(this), 150)();
  }
  render() {
    return (
      <BrowserRouter>
        <div className={"appRootEl"}>

          <Notifications />

          <LoadingOverlay
            active={this.props.ui.loading}
            message={this.props.ui.loadingMessage.message}
            hideOverlay={() => this.props.setOverlay(0)}
          />

          <MessageOverlay overlay={this.props.ui.overlay} hideOverlay={() => this.props.setOverlay(0)} />

          <div className={"row no-gutters "}>
            <div className={"offset-md-0 col-md-12 no-gutters mainContainer"}>
              <Header />
              <Body>
                <SocketContext.Provider value={io}>
                  {this.props.ui.appLoaded && <AppRouter />}
                </SocketContext.Provider>
              </Body>
              <Footer />
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
