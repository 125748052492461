import React, { useEffect, useState } from "react";
// import api, { nodeService } from "../../../Utils/Api";
import { formToObject } from "../../../Utils/Helpers";



const TaskSettings = () => {


    const [types, setTypes] = useState([]);

    
    const loadTaskTypes = () => {
        // nodeService.get('/task')
        // .then(res => {

        //     if(res.status === 200)
        //         setTypes(res.data);

        // })
    };

    useEffect(loadTaskTypes, []);


    const submitNewTaskType = e => {
        e.preventDefault();
        // nodeService.post('/task/add', formToObject(e.target)).then(loadTaskTypes);
        e.target.reset();
    }

    const deleteTaskType = id => {
        // nodeService.delete(`/task/${id}`)
        // .then(res => loadTaskTypes());
    }


    return (
        <>
            <h3>Types de tâches</h3>
            <form onSubmit={submitNewTaskType}>
                {}
                <input type="text" name="name" className={"form-control"} placeholder={"Name"} />
                <input type="text" name="label" className={"form-control"} placeholder={"Label"} />
                <button className={"btn btn-primary btn-sm"}>Ajouter</button>
            </form>
            <div className={"row mt-5"}>
                <ul>

                    {types.map(item => (
                        <li>
                            {item.label}

                            <button type="button" onClick={() => deleteTaskType(item._id)} className={"btn btn-sm btn-danger"}>Supprimer</button>
                            <ul>
                                {item.children.map(step => <li>{step.label}</li>)}
                            </ul>
                        </li>
                    ))}
                </ul>

                
            </div>

        </>
    )
}

export default TaskSettings;