export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function ConvertDDToDMS(D , lng){
  return [0|D, '° ', 0|(D=(D<0?-D:D)+1e-4)%1*60, "' ", 0|D*60%1*60, '"' ,  D < 0 ? (lng ? " W" : " S") : lng ? " E" : " N"].join('');
}

export function convertDMSToDD (loc) {
  let localisation = loc.replace(/\D+/g, ' ').trim().split(' ').map(e => parseInt(e));
  let direction = loc.replace(/[^a-z]/gi, '')
  // let dd = +degrees + +minutes / 60 + +seconds / (60 * 60);
  // if (direction === "S" || direction === "W") {
  //   dd = dd * -1;
  // }

  let dd = +localisation[0] + +localisation[1]  / 60 + +localisation[2]  / (60 * 60);
  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  }
  return dd;
}

export function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const buildFilters = (filters = {}) =>
  Object.keys(filters)
    .filter(key => String(filters[key]).length > 0)
    .map(key => key + "=" + filters[key])
    .join("&");

export const formToObject = form => {
  const formdata = new FormData(form);

  let data = {};

  for (var [key, value] of formdata.entries()) data[key] = value;

  return data;
};

export const chartColors = [
  "rgb(78, 121, 167)",
  "rgb(242, 142, 43)",
  "rgb(225, 87, 89)",
  "rgb(118, 183, 178)",
  "rgb(89, 161, 79)",
  "rgb(237, 201, 72)",
  "rgb(176, 122, 161)",
  //
  "rgb(0,63,92)",
  "rgb(55,76,128)",
  "rgb(122,81,149)",
  "rgb(188,80,144)",
  "rgb(239,86,117)",
  "rgb(255,118,74)",
  "rgb(255,166,0)",
  "rgb(0, 255, 30)"
];

export const gradientColors = [
  "rgb(255,247,251)",
  "rgb(236,231,242)",
  "rgb(208,209,230)",
  "rgb(166,189,219)",
  "rgb(116,169,207)",
  "rgb(54,144,192)",
  "rgb(5,112,176)",
  "rgb(4,90,141)",
  "rgb(2,56,88)"
];

export const definitions = {
  ac: {
    type: {
      monobloc: "Monobloc",
      split: "Split"
    }
  },
  shelter: {
    1: "Indoor",
    2: "Local technique",
    3: "Outdoor",
    other: "Autre"
  },
  typology: {
    other: "Autre",
    rooftop: "Rooftop",
    greenfield: "Green Field"
  },
  bss: {
    other: "Autre",
    alcatel: "Alcatel",
    nsn: "NSN",
    huawei2g: "Huawei 2G",
    huawei3g: "Huawei 3G",
    huwaei3g: "Huawei 3G",
    zte2g: "ZTE 2G",
    zte3g: "ZTE 3G"
  },

  power: {
    types: {
      "0": "SAFT MP54",
      "1": "SAFT MP75",
      "2": "SAFT MTP 69",
      "3": "SAFT MTP 100",
      "4": "Huawei",
      "5": "Siemens 2008",
      "6": "Siemens 4102",
      "7": "ASCOM 1200",
      "8": "ASCOM 2000",
      "9": "AEG",
      "10": "Benning 12(15)A",
      "11": "Benning 34A",
      "12": "Benning 50A",
      "13": "Emerson Electric ",
      "14": "Autres ( Indiquer)"
    }
  }
};
//chartColors;

// export default {
//     validateEmail: validateEmail,
//     chartColors: chartColors
// }
