import React, { useEffect , useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {convertDMSToDD} from '../../../../Utils/Helpers'
import {isJsonString} from '../../../../Utils/Helpers'
import Modal from 'react-bootstrap/Modal'


const moment = require("moment");

const capacity = Array(30).fill(0);
const countC = [3, 7, 7, 9, 9]


const Access = ({ data, active, geo, onchange, id, task,   ...props }) => {

    const [show, setShow] = useState(false);
    const [selectedTable, setSelectedTable] = useState('');
    const [count, setCount] = useState(0);
    const [selectedItem, setSelectedItem] = useState([]);

    const [items, setItems] = useState(data.items);
    const [items_rad_ant, setItemsRadAnt] = useState(data.items_rad_ant);
    const [items_trans, setItemsTrans] = useState(data.items_trans);
    const [items_mast, setItemsMast] = useState(data.items_mast);
    const [items_mast2, setItemsMast2] = useState(data.items_mast2);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let value = ''
    const _handleKeyDown = (e, id, test, field,  isTable, isPower, att) => {
      if (e.key === 'Enter') {
        onchange(id, test, field , value,  isTable, isPower, att )
      }
    }
  

    const updateVal = (e) => {
      value = e.target ? e.target.value : e.value;
      console.log(value)
    }


    const add = () => {
      let array
      switch (selectedTable) {
        case 'Radio' : 
          array = items
          //array.push(Array(countC[count]).fill(''))
          array.push({ref : '' , Qty : '' , Remark_radioconf : ''})
          setItems([...array])
          break;
        case 'Radio antenna' : 
          array = items_rad_ant
          array.push({tower_legs : '' , sectors : '' , hba : ''  , azimut : '' , rru_900 : '' , rru_1800 : '' ,rru_2100 : ''  })
          setItemsRadAnt([...array])
          break;
        case 'Transmission Part' : 
          array = items_trans
          array.push({mw_antenna : '' , tower_legs : '' , hba_t : ''  , azimut_t : '' , diameter : '' , vendor : '' ,comment_t : ''  })
          setItemsTrans([...array])
          break;
        case 'Tower 1' : 
          array = items_mast
          array.push({mast : '' , heigth : '' , Constructor_subcontractor : '' , EPA_SDF : '' , Wheelbase_Bottom : '' , dist_entre_pole : '' , diameter_at_top : '' ,diameter_at_bottom : '' , barcon_foot: ''  })
          setItemsMast([...array])
          break;
        case 'Haubané/autostable' : 
          array = items_mast2
          array.push({mast : '' , heigth : '' , Constructor_subcontractor : '' , EPA_SDF : '' , Wheelbase_Bottom : '' , dist_entre_pole : '' , diameter_at_top : '' ,diameter_at_bottom : '' , barcon_foot: ''  })
          setItemsMast2([...array])
          break;
      }
    }

    const send = () => {
      switch (selectedTable) {
        case 'Radio' : 
          onchange(id, 'access', 'items' , items , true)
          break;
        case 'Radio antenna' : 
          onchange(id, 'access', 'items_rad_ant' , items_rad_ant , true)
          break;
        case 'Transmission Part' : 
          onchange(id, 'access', 'items_trans' , items_trans , true)
          break;
        case 'Tower 1' : 
          onchange(id, 'access', 'items_mast' , items_mast , true)
          break;
        case 'Haubané/autostable' : 
          onchange(id, 'access', 'items_mast2' , items_mast2 , true)
          break;
      }
      setShow(false)
    }

    const cancel = () => {
      // setItems(data.Items)
      // setItemsRadAnt(data.items_rad_ant)
      // setItemsTrans(data.items_trans)
      // setItemsMast(data.items_mast)
      // setItemsMast2(data.items_mast2)

    }

    const update = (item, index,e ) => {
        let newArr
        const value = e.target ? e.target.value : e.value;
      switch (selectedTable){

        case 'Radio': 
          newArr = [...items];
          newArr[index][item] = value;
          setItems(newArr);
          break;
        case 'Radio antenna' : 
          newArr = [...items_rad_ant];
          newArr[index][item] = value;
          setItemsRadAnt(newArr);
          break;
        case 'Transmission Part' : 
          newArr = [...items_trans];
          newArr[index][item] = value;
          setItemsTrans(newArr);
          break;
        case 'Tower 1' : 
          newArr = [...items_mast];
          newArr[index][item] = value;
          setItemsMast(newArr);
          break;
        case 'Haubané/autostable' : 
          newArr = [...items_mast2];
          newArr[index][item] = value;
          setItemsMast2(newArr);
          break;
      }

    }

    const remove = (index) => {
        var newArr ; 
      // console.log('remove' + array)
      // if (index !== -1) {
      //     array.splice(index, 1);
      //     setTab([...array]);
      // }

      switch (selectedTable){

        case 'Radio': 
          newArr = [...items];
          if (index !== -1) {
              newArr.splice(index, 1);
              setItems(newArr);
          }
          break;
        case 'Radio antenna' : 
          newArr = [...items_rad_ant];
          if (index !== -1) {
            newArr.splice(index, 1);
            setItemsRadAnt(newArr);
          }
          break;
        case 'Transmission Part' : 
          newArr = [...items_trans];
          if (index !== -1) {
            newArr.splice(index, 1);
            setItemsTrans(newArr);
          }
          break;
        case 'Tower 1' : 
          newArr = [...items_mast];
          if (index !== -1) {
            newArr.splice(index, 1);
            setItemsMast(newArr);
          }
          break;
        case 'Haubané/autostable' : 
          newArr = [...items_mast2];
          if (index !== -1) {
            newArr.splice(index, 1);
            setItemsMast2(newArr);
          }
          break;
      }
    }
  

  let  loc = data.site.site_address_position && isJsonString(data.site.site_address_position) ? JSON.parse(data.site.site_address_position.replace(/\s+/g, ' ')) : ''

  return (
    <>
     <h6>General Infromation</h6>
      <div className={"row col-md-6"}>
        <label>Date de début du site survey : </label>
        {/*data.site.ssr_start_date*/data.ssr_start_date}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de début du site survey : </label>
        {/*data.site.ssr_start_time*/data.ssr_start_time}
      </div>

      <div className={"row col-md-6"}>
        <label>Date de fin du site survey : </label>
        {/*data.site.ssr_end_date*//*data.ssr_end_date*/moment(data.finished_at).format("YYYY-MM-DD")}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de fin du site survey : </label>
        {/*data.site.ssr_end_time*//*data.ssr_end_time*/moment(data.finished_at).format("HH:mm:ss")}
      </div>
{/* 
      <br />
      <h6>Données du site</h6> */}

      <div className={"row col-md-6"}>
        <label>Code site : </label>
        {data.site.site_code}
      </div>

      <div className={"row col-md-6"}>
        <label>Nom du site : </label>
        {data.site.site_name}
      </div>

      <div className={"row col-md-6"}>
        <label>GPS : </label>
        {/* {data.site.site_address_position} */}
        {/* {(data.site.lat && data.site.lng) && <> Lat: {  !!(data.site.lat % 1)  ?  data.site.lat   : convertDMSToDD(data.site.lat) }, Lng: {!!(data.site.lng % 1)  ?   data.site.lng  :convertDMSToDD(data.site.lng)}</>}  */}
          {/* : 
        (geo && geo.lat && geo.lng) ?  <>Lat: {geo.lat } Lng : {geo.lng }</> : 
     (data.site.lat && data.site.lng)? <>  Lat: { convertDMSToDD(loc.lat)} Lng: {convertDMSToDD(loc.lng)}</> : <></>}<br/> */}
        {data.site.lat &&  <> Lat: {  !!(data.site.lat % 1)  ?  data.site.lat   : convertDMSToDD(data.site.lat) }, </>}
        {data.site.lng &&  <> Lat: {  !!(data.site.lng % 1)  ?  data.site.lng   : convertDMSToDD(data.site.lng) } </>}

       <div>
          
        Lat: <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'lat')}  onChange = {(e) => updateVal(e)}/><br/>
        Lng: <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'lng')}  onChange = {(e) => updateVal(e)}/>
       </div>

       
      </div>
      <div className={"row col-md-6"}>
        <label>GPS Tag from photo : </label>
        Lat : {task.lat} ,  Lng : {task.lng}         
      </div>
      <div className={"row col-md-6"}>
        <label>Adresse du site : </label>
        {data.site.site_address_info}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'site_address_info')}  onChange = {(e) => updateVal(e)}/>
      </div>
      <div className={"row col-md-6"}>
        <label>Topology: </label>
        {data.site.typology}
        {/* <input type="text"  value={data.site.typology} onChange= {(e) => onchange(id, 'access' , 'typology' , e)}/> */}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'typology')}  onChange = {(e) => updateVal(e)}/>
      </div>
      <div className={"row col-md-6"}>
        <label>Height(m): </label>
        {data.site.height}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'height')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Site Location: </label>
        {data.site.site_location}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'site_location')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <br />
      <h6>Site Access Information</h6>

      <div className={"row col-md-6"}>
        <label>Site Ownership : </label>
        {data.site.ownership}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'ownership')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Other : </label>
        {data.site.other}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'other')}  onChange = {(e) => updateVal(e)}/>
      </div>


      <div className={"row col-md-6"}>
        <label>Nom : </label>
        {data.site.agent_name}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'agent_name')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Mobile : </label>
        {data.site.agent_mobile}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'agent_mobile')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Site access : </label>
        {data.site.access}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'access')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Comments : </label>
        {data.site.comments}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'comments')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Special Access procedure : </label>
        {data.site.access_procedure}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'access_procedure')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Details : </label>
        {data.site.details}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'details')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Key of Outdoor equipment : </label>
        {data.site.key_ofoutdoor_equipment}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'key_ofoutdoor_equipment')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Details : </label>
        {data.site.details_equipment}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'details_equipment')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <br />
      <h6>Delivery Method</h6>

      <div className={"row col-md-6"}>
        <label> Easy access : </label>
        {data.site.easy_access}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'easy_access')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Difficult access : </label>
        {data.site.difficult_access}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'difficult_access')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Building height (m) (RT site) : </label>
        {data.site.building_height}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'building_height')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label> Equipment Delivery method : </label>
        {data.site.equipment_delivery_method}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'equipment_delivery_method')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Comments: </label>
        {data.site.comments_eqp_delv}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'comments_eqp_delv')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <br />
      <h6>Radio Part</h6>

      <div className={"row col-md-6"}>
        <label>General Location: </label>
        {data.site.genereal_location}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'genereal_location')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Site radio configuration: </label>
        {data.site.radio_conf && data.site.radio_conf.map((val, index) => (
          <>{val} </>
        ))}


      </div>
      <label>Radio : <button onClick={() => { setSelectedTable('Radio'); setShow(true) }}> Modifier</button> </label>
      {items.length > 0 &&  <div className={"col-md-7 "}>
          <div className="row border">
              <div className="col-md-4"><b>Refferance</b></div>
              <div className="col-md-4"><b>Qty</b></div>
              <div className="col-md-4"><b>Remark</b></div>
          </div>
          
          {items.map(item => (
            <div className="row border">
              <div className="col-md-4">{item.ref ? item.ref : '' }</div>
              <div className="col-md-4">{item.Qty ? item.Qty : ''}</div>
              <div className="col-md-4">{item.Remark_radioconf ? item.Remark_radioconf : '' }</div>
            </div>
          ))}
      </div>}
      <br/>
      <label>Radion Antenna  : <button onClick={() => { setSelectedTable('Radio antenna'); setShow(true) ; setCount(1)}}> Modifier</button> </label>
      {items_rad_ant.length > 0 &&  <div className={"col-md-10"}>
          <div className="row border">
              <div className="col-md-2"><b>Tower Legs</b></div>
              <div className="col-md-2"><b>Sectors</b></div>
              <div className="col-md-1"><b>HBA TERASSE</b></div>
              <div className="col-md-1"><b>HBA SOL</b></div>
              <div className="col-md-1"><b>Azimut</b></div>
              <div className="col-md-1"><b>RRU 900 HBA</b></div>
              <div className="col-md-2"><b>RRU 1800 HBA</b></div>
              <div className="col-md-2"><b>RRU2100 HBA</b></div>
          </div>
          
          {items_rad_ant.map(item => (
            <div className="row border">
              <div className="col-md-2">{item.tower_legs ? item.tower_legs : '' }</div>
              <div className="col-md-2">{item.sectors ? item.sectors : '' }</div>
              <div className="col-md-1">{item.hba ? item.hba : '' }</div>
              <div className="col-md-1">{item.hba ? item.hba : '' }</div>
              <div className="col-md-1">{item.azimut ? item.azimut : '' }</div>
              <div className="col-md-1">{item.rru_900 ? item.rru_900 : '' }</div>
              <div className="col-md-2">{item.rru_1800 ? item.rru_1800 : '' }</div>
              <div className="col-md-2">{item.rru_2100 ? item.rru_2100 : '' }</div>
            </div>
          ))}
      </div>}


      <div className={"row col-md-6"}>
        <label>Busbar  free : </label>
        {data.site.busbar_free}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'busbar_free')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Place for New busbar : </label>
        {data.site.place_new_busbar}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'place_new_busbar')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Space for NEW BTS without reorganisation : </label>
        {data.site.space_new_bts}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'space_new_bts')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Comments : </label>
        {data.site.comments_space_new_bts}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'comments_space_new_bts')}  onChange = {(e) => updateVal(e)}/>
      </div>
      <div className={"row col-md-6"}>
        <label>Equipment Mounting radio ODU : </label>
        {data.site.em_odu}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'em_odu')}  onChange = {(e) => updateVal(e)}/>
      </div>
      <div className={"row col-md-6"}>
        <label>Space for radio ODU on existing Support: </label>
        {data.site.space_radio_odu}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'space_radio_odu')}  onChange = {(e) => updateVal(e)}/>
      </div>
      <div className={"row col-md-6"}>
        <label>New Radio ODU support required: </label>
        {data.site.new_radio_odu}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'new_radio_odu')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <br />
      <h6>Transmission Part </h6>

      <button onClick={() => { setSelectedTable('Transmission Part'); setShow(true) ; setCount(2)}}> Modifier</button>
      {items_trans.length > 0 &&  <div className={"col-md-10 "}>
          <div className="row border">
            <div className="col-md-2"><b>MW Antenna</b></div>
              <div className="col-md-2"><b>Tower Legs</b></div>
              <div className="col-md-1"><b>HBA TERASSE</b></div>
              <div className="col-md-1"><b>HBA SOL</b></div>
              <div className="col-md-1"><b>Azimut</b></div>
              <div className="col-md-1"><b>Diameter</b></div>
              <div className="col-md-2"><b>Vendor</b></div>
              <div className="col-md-2"><b>comment</b></div>
          </div>
          
          {items_trans.map(item => (
            <div className="row border">
              <div className="col-md-2">{item.mw_antenna ? item.mw_antenna : '' }</div>
              <div className="col-md-2">{item.tower_legs ? item.tower_legs : ''}</div>
              <div className="col-md-1">{item.hba_t ? item.hba_t : ''}</div>
              <div className="col-md-1">{item.hba_t ? item.hba_t : ''}</div>
              <div className="col-md-1">{item.azimut_t ? item.azimut_t : ''}</div>
              <div className="col-md-1">{item.diameter ?  item.diameter : ''}</div>
              <div className="col-md-2">{item.vendor ?  item.vendor : ''}</div>
              <div className="col-md-2">{item.comment_t ? item.comment_t : ''}</div>
            </div>
          ))}
      </div>}

      <br />
      <h6>Power/energy/site equipment </h6>

      <div className={"row col-md-6"}>
        <label>AC Power system Type: </label>
        {data.site.ac_power}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'ac_power')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>AC Power Type: </label>
        {data.site.ac_power_type}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'ac_power_type')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Generator available: </label>
        {data.site.generator_available}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'generator_available')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Brand: </label>
        {data.site.brand}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'brand')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Nomber * Capacity (Kva): </label>
        {data.site.nbr_cap_kva}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'nbr_cap_kva')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Remark: </label>
        {data.site.remark}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'remark')}  onChange = {(e) => updateVal(e)}/>
      </div>


      <br />
      <h6>Power cabinet indoor</h6>

      {data.items_powerindoor && data.items_powerindoor.map(item => (
            <div className={" col-md-6"}>
              <label>Power cabinet: </label>{item.power_cabinet} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'power_cabinet')}  onChange = {(e) => updateVal(e)}/> <br/>
              <label>REFRENCE: </label>{item.model}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'model')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Power Rack Type: </label>{item.power_rack_type}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'power_rack_type')}  onChange = {(e) => updateVal(e)}/> <br/>
              <label>Rectifiers (PSU): </label>{item.rectifiers}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'rectifiers')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Quantity: </label>{item.quantity_psu}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'quantity_psu')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>TYPE: </label>{item.type_psu}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'type_psu')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Reference rectifier: </label>{item.reference_rectifiers_psu} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'reference_rectifiers_psu')}  onChange = {(e) => updateVal(e)}/> <br/>
              <label>Available place for new power racks: </label>{item.available_new_racks}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'available_new_racks')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Available Free  breakers: </label>{item.available_free_breakers}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'available_free_breakers')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery Capacity (AH): </label>{item.battery_capacity} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'battery_capacity')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery Voltage  (Single cell) (V): </label>{item.battery_voltage} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'battery_voltage')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery Set (-48) quantity): </label>{item.battery_set} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'battery_set')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery brand: </label>{item.battery_brand} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'battery_brand')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Equipment  busbar: </label> {item.equipment_busbar} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'equipment_busbar')}  onChange = {(e) => updateVal(e)}/><br/>
              {
                capacity.map((cap, index) => (
                  <div className={"row col-md-6"}>
                    <label>Free Bearker {index+1} :  </label>
                    {item['capacity_'+ (index+1)]}
                    <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_powerindoor' , true , true , 'capacity_'+(index+1))}  onChange = {(e) => updateVal(e)}/>
                  </div>
                ))
              }
              <br/> <br/>
            </div>
      ))}
       <h6>Power cabinet Outdoor</h6>
      {data.items_poweroutdoor && data.items_poweroutdoor.map(item => (
            <div className={" col-md-6"}>
              <label>Power cabinet: </label>{item.power_cabinet} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'power_cabinet')}  onChange = {(e) => updateVal(e)}/> <br/>
              <label>REFRENCE: </label>{item.model}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'model')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Power Rack Type: </label>{item.power_rack_type}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'power_rack_type')}  onChange = {(e) => updateVal(e)}/> <br/>
              <label>Rectifiers (PSU): </label>{item.rectifiers}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'rectifiers')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Quantity: </label>{item.quantity_psu}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'quantity_psu')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>TYPE: </label>{item.type_psu}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'type_psu')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Reference rectifier: </label>{item.reference_rectifiers_psu} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'reference_rectifiers_psu')}  onChange = {(e) => updateVal(e)}/> <br/>
              <label>Available place for new power racks: </label>{item.available_new_racks}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'available_new_racks')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Available Free  breakers: </label>{item.available_free_breakers}  <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'available_free_breakers')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery Capacity (AH): </label>{item.battery_capacity} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'battery_capacity')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery Voltage  (Single cell) (V): </label>{item.battery_voltage} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'battery_voltage')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery Set (-48) quantity): </label>{item.battery_set} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'battery_set')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Battery brand: </label>{item.battery_brand} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'battery_brand')}  onChange = {(e) => updateVal(e)}/><br/>
              <label>Equipment  busbar: </label> {item.equipment_busbar} <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'equipment_busbar')}  onChange = {(e) => updateVal(e)}/><br/>
              

              {
                capacity.map((cap, index) => (
                  <div className={"row col-md-6"}>
                    <label>Free Bearker {index+1} :  </label>
                    {item['capacity_'+ (index+1)]}
                    <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'items_poweroutdoor' , true , true , 'capacity_'+(index+1))}  onChange = {(e) => updateVal(e)}/>
                  </div>
                ))
              }
              <br/> <br/>
            </div>
      ))}


      {/* <div className={"row col-md-6"}>
        <label>Power cabinet: </label>
        {data.site.power_cabinet}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'power_cabinet')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Model: </label>
        {data.site.model}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'model')}  onChange = {(e) => updateVal(e)}/>
      </div>
      
      <div className={"row col-md-6"}>
        <label>Power Rack Type: </label>
        {data.site.power_rack_type}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'power_rack_type')}  onChange = {(e) => updateVal(e)}/>
      </div>
      <div className={"row col-md-6"}>
        <label>Rectifiers (PSU): </label>
        {data.site.rectifiers}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'rectifiers')}  onChange = {(e) => updateVal(e)}/>
      </div>
      <div className={"row col-md-6"}>
        <label>Quantity: </label>
        {data.site.quantity_psu}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'quantity_psu')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>TYPE: </label>
        {data.site.type_psu}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'type_psu')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Available place for new power racks: </label>
        {data.site.available_new_racks}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'available_new_racks')}  onChange = {(e) => updateVal(e)}/>
      </div>

      <div className={"row col-md-6"}>
        <label>Available Free  breakers: </label>
        {data.site.available_free_breakers}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'available_free_breakers')}  onChange = {(e) => updateVal(e)}/>
      </div> */}

      {/* <div className={"row col-md-6"}>
        <label>Capacity 1: </label>
        {data.site.capacity_1}
      </div>
      <div className={"row col-md-6"}>
        <label>Capacity 2: </label>
        {data.site.capacity_2}
      </div>
      <div className={"row col-md-6"}>
        <label>Capacity 3: </label>
        {data.site.capacity_3}
      </div>
      <div className={"row col-md-6"}>
        <label>Capacity 4: </label>
        {data.site.capacity_4}
      </div>
      <div className={"row col-md-6"}>
        <label>Capacity 5: </label>
        {data.site.capacity_5}
      </div> */}

      {/* <div className={"row col-md-6"}>
        <label>Battery Capacity (AH): </label>
        {data.site.battery_capacity}
      </div>

      <div className={"row col-md-6"}>
        <label>Battery Voltage  (Single cell) (V): </label>
        {data.site.battery_voltage}
      </div>

      <div className={"row col-md-6"}>
        <label>Battery Set (-48) quantity): </label>
        {data.site.battery_set}
      </div>

      <div className={"row col-md-6"}>
        <label>Battery brand: </label>
        {data.site.battery_brand}
      </div>

      <div className={"row col-md-6"}>
        <label>Equipment  busbar: </label>
        {data.site.equipment_busbar}
      </div> */}



      
      <label>Tower 1 :  </label>
      <button onClick={() => { setSelectedTable('Tower 1'); setShow(true) ; setCount(3)}}> Modifier</button>
      <div className={"col-md-12 "}>
          <div className="row border">
              <div className="col-md-1"><b></b></div>
              <div className="col-md-1"><b>Heigth</b></div>
              <div className="col-md-2"><b>Constructor / subcontractor</b></div>
              <div className="col-md-1"><b>EPA (SDF)</b></div>
              <div className="col-md-1"><b>Wheelbase Bottom</b></div>
              <div className="col-md-2"><b>Distance entre pôle et fixation bracon (3D)</b></div>
              <div className="col-md-2"><b>Foot diameter at the bottom</b></div>
              <div className="col-md-1"><b>Foot diameter at the top</b></div>
              <div className="col-md-1"><b>Bracon Foot diameter</b></div>
          </div>
          {items_mast && items_mast.map(item => (
            <div className="row border">
              <div className="col-md-1">{item.mast ? item.mast : '' }</div>
              <div className="col-md-1">{item.heigth ? item.heigth : ''}</div>
              <div className="col-md-2">{item.Constructor_subcontractor ? item.Constructor_subcontractor : ''}</div>
              <div className="col-md-1">{item.EPA_SDF ? item.EPA_SDF  : ''}</div>
              <div className="col-md-1">{item.Wheelbase_Bottom? item.Wheelbase_Bottom : ''}</div>
              <div className="col-md-2">{item.dist_entre_pole ? item.dist_entre_pole  : ''}</div>
              <div className="col-md-2">{item.diameter_at_top ? item.diameter_at_top : ''}</div>
              <div className="col-md-1">{item.diameter_at_bottom ? item.diameter_at_bottom  : ''}</div>
              <div className="col-md-1">{item.barcon_foot ? item.barcon_foot  : ''}</div>
              
            </div>
          ))}
      </div>

      <br/>
      <label>Haubané/autostable: </label>
      <button onClick={() => { setSelectedTable('Haubané/autostable'); setShow(true)  ; setCount(4)}}> Modifier</button>
      <div className={"col-md-12 "}>
          <div className="row border">
              <div className="col-md-1"><b></b></div>
              <div className="col-md-1"><b>Heigth</b></div>
              <div className="col-md-2"><b>Constructor / subcontractor</b></div>
              <div className="col-md-1"><b>EPA (SDF)</b></div>
              <div className="col-md-1"><b>Wheelbase Bottom</b></div>
              <div className="col-md-2"><b>Distance entre pôle et fixation bracon (3D)</b></div>
              <div className="col-md-2"><b>Foot diameter at the bottom</b></div>
              <div className="col-md-1"><b>Foot diameter at the top</b></div>
              <div className="col-md-1"><b>Bracon Foot diameter</b></div>
          </div>
          {items_mast2 && items_mast2.map(item => (
            <div className="row border">
              <div className="col-md-1">{item.mast ? item.mast : '' }</div>
              <div className="col-md-1">{item.heigth ? item.heigth : ''}</div>
              <div className="col-md-2">{item.Constructor_subcontractor ? item.Constructor_subcontractor : ''}</div>
              <div className="col-md-1">{item.EPA_SDF ? item.EPA_SDF  : ''}</div>
              <div className="col-md-1">{item.Wheelbase_Bottom? item.Wheelbase_Bottom : ''}</div>
              <div className="col-md-2">{item.dist_entre_pole ? item.dist_entre_pole  : ''}</div>
              <div className="col-md-2">{item.diameter_at_top ? item.diameter_at_top : ''}</div>
              <div className="col-md-1">{item.diameter_at_bottom ? item.diameter_at_bottom  : ''}</div>
              <div className="col-md-1">{item.barcon_foot ? item.barcon_foot  : ''}</div>
              
            </div>
          ))}
        </div>
            




      <div className={"row col-md-6"}>
        <label>Additional Remarks (If any structure damage or missing parts) : </label>
        {data.site.add_remark}
        <input type="text"   onKeyDown={(e) => _handleKeyDown(e , id, 'access' , 'add_remark')}  onChange = {(e) => updateVal(e)}/>
      </div>



    <Modal show={show} onHide={handleClose} animation={false} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Modifier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedTable === 'Radio'  && 
            <div className={"col-md-7 "}>
              <div className="row border">
                  <div className="col-md-4"><b>Refferance</b></div>
                  <div className="col-md-4"><b>Qty</b></div>
                  <div className="col-md-4"><b>Remark</b></div>
              </div>
              
              {items.map((item, index) => (
                <div className="row border">
                  <div className="col-md-4"> <input type="text" className="form-control-plaintext" value={item.ref ? item.ref : '' }   onChange={e => update('ref', index, e)}/></div>
                  <div className="col-md-4"> <input type="text" className="form-control-plaintext" value={item.Qty ? item.Qty : '' }  onChange={e => update('Qty', index, e)}/></div>
                  <div className="col-md-3"> <input type="text"  className="form-control-plaintext" value={item.Remark_radioconf ? item.Remark_radioconf : '' } onChange={e => update('Remark_radioconf', index, e)} /></div>
                  <div className="col-md-1"><button  onClick={e => remove(index) }  className={"rounded-circle btn btn-sm"}> <FontAwesomeIcon icon={faTimes} /></button></div>
                </div>
              ))}
          </div>
        }

        { selectedTable === 'Radio antenna' && 
          <div className={"col-md-10"}>
            <div className="row border">
                <div className="col-md-2"><b>Tower Legs</b></div>
                <div className="col-md-2"><b>Sectors</b></div>
                <div className="col-md-1"><b>HBA TERASSE</b></div>
                {/* <div className="col-md-1"><b>HBA SOL</b></div> */}
                <div className="col-md-1"><b>Azimut</b></div>
                <div className="col-md-1"><b>RRU 900 HBA</b></div>
                <div className="col-md-2"><b>RRU 1800 HBA</b></div>
                <div className="col-md-2"><b>RRU2100 HBA</b></div>
            </div>
            
            {items_rad_ant.map((item, index) => (
              <div className="row border">
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.tower_legs ? item.tower_legs : '' }   onChange={e => update('tower_legs', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.sectors ? item.sectors : '' }   onChange={e => update('sectors', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.hba ? item.hba : '' }   onChange={e => update('hba', index, e)}/></div>
                {/* <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.hba ? item.hba : '' }   onChange={e => update('hba', index, e)}/></div> */}
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.azimut ? item.azimut : '' }   onChange={e => update('azimut', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.rru_900 ? item.rru_900 : '' }   onChange={e => update('rru_900', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.rru_1800 ? item.rru_1800 : '' }   onChange={e => update('rru_1800', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.rru_2100 ? item.rru_2100 : '' }   onChange={e => update('rru_2100', index, e)}/></div>
                <div className="col-md-1"><button  onClick={e => remove(index) }  className={"rounded-circle btn btn-sm"}> <FontAwesomeIcon icon={faTimes} /></button></div>
              </div>
            ))}
        </div>}

        {selectedTable === 'Transmission Part' &&
          <div className={"col-md-10 "}>
            <div className="row border">
              <div className="col-md-2"><b>MW Antenna</b></div>
                <div className="col-md-2"><b>Tower Legs</b></div>
                <div className="col-md-1"><b>HBA TERASSE</b></div>
                {/* <div className="col-md-1"><b>HBA SOL</b></div> */}
                <div className="col-md-1"><b>Azimut</b></div>
                <div className="col-md-1"><b>Diameter</b></div>
                <div className="col-md-2"><b>Vendor</b></div>
                <div className="col-md-2"><b>comment</b></div>
            </div>
            
            {items_trans.map((item , index) => (
              <div className="row border">
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.mw_antenna ? item.mw_antenna : '' }   onChange={e => update('mw_antenna', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.tower_legs ? item.tower_legs : '' }   onChange={e => update('tower_legs', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.hba_t ? item.hba_t : '' }   onChange={e => update('hba_t', index, e)}/></div>
                {/* <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.hba_t ? item.hba_t : '' }   onChange={e => update('hba_t', index, e)}/></div> */}
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.azimut_t ? item.azimut_t : '' }   onChange={e => update('azimut_t', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.diameter ? item.diameter : '' }   onChange={e => update('diameter', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.vendor ? item.vendor : '' }   onChange={e => update('vendor', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.comment_t ? item.comment_t : '' }   onChange={e => update('comment_t', index, e)}/></div>
                <div className="col-md-1"><button  onClick={e => remove(index) }  className={"rounded-circle btn btn-sm"}> <FontAwesomeIcon icon={faTimes} /></button></div>
              </div>
            ))}
        </div>
              
        }

        {selectedTable === 'Tower 1' &&
          <div className={"col-md-12 "}>
            <div className="row border">
                <div className="col-md-1"><b></b></div>
                <div className="col-md-1"><b>Heigth</b></div>
                <div className="col-md-2"><b>Constructor / subcontractor</b></div>
                <div className="col-md-1"><b>EPA (SDF)</b></div>
                <div className="col-md-1"><b>Wheelbase Bottom</b></div>
                <div className="col-md-2"><b>Distance entre pôle et fixation bracon (3D)</b></div>
                <div className="col-md-1"><b>Foot diameter at the bottom</b></div>
                <div className="col-md-1"><b>Foot diameter at the top</b></div>
                <div className="col-md-1"><b>Bracon Foot diameter</b></div>
            </div>
            {items_mast && items_mast.map((item, index) => (
              <div className="row border">
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.mast ? item.mast : '' }   onChange={e => update('mast', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.heigth ? item.heigth : '' }   onChange={e => update('heigth', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.Constructor_subcontractor ? item.Constructor_subcontractor : '' }   onChange={e => update('Constructor_subcontractor', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.EPA_SDF ? item.EPA_SDF : '' }   onChange={e => update('EPA_SDF', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.Wheelbase_Bottom ? item.Wheelbase_Bottom : '' }   onChange={e => update('Wheelbase_Bottom', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.dist_entre_pole ? item.dist_entre_pole : '' }   onChange={e => update('dist_entre_pole', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.diameter_at_top ? item.diameter_at_top : '' }   onChange={e => update('diameter_at_top', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.diameter_at_bottom ? item.diameter_at_bottom : '' }   onChange={e => update('diameter_at_bottom', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.barcon_foot ? item.barcon_foot : '' }   onChange={e => update('barcon_foot', index, e)}/></div>
                <div className="col-md-1"><button  onClick={e => remove(index) }  className={"rounded-circle btn btn-sm"}> <FontAwesomeIcon icon={faTimes} /></button></div>
                
              </div>
            ))}
          </div>
        }

        {selectedTable === 'Haubané/autostable' &&

          <div className={"col-md-12 "}>
          <div className="row border">
              <div className="col-md-1"><b></b></div>
              <div className="col-md-1"><b>Heigth</b></div>
              <div className="col-md-2"><b>Constructor / subcontractor</b></div>
              <div className="col-md-1"><b>EPA (SDF)</b></div>
              <div className="col-md-1"><b>Wheelbase Bottom</b></div>
              <div className="col-md-2"><b>Distance entre pôle et fixation bracon (3D)</b></div>
              <div className="col-md-1"><b>Foot diameter at the bottom</b></div>
              <div className="col-md-1"><b>Foot diameter at the top</b></div>
              <div className="col-md-1"><b>Bracon Foot diameter</b></div>
          </div>
          {items_mast2 && items_mast2.map((item, index) => (
            <div className="row border">
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.mast ? item.mast : '' }   onChange={e => update('mast', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.heigth ? item.heigth : '' }   onChange={e => update('heigth', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.Constructor_subcontractor ? item.Constructor_subcontractor : '' }   onChange={e => update('Constructor_subcontractor', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.EPA_SDF ? item.EPA_SDF : '' }   onChange={e => update('EPA_SDF', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.Wheelbase_Bottom ? item.Wheelbase_Bottom : '' }   onChange={e => update('Wheelbase_Bottom', index, e)}/></div>
                <div className="col-md-2"><input type="text" className="form-control-plaintext" value={item.dist_entre_pole ? item.dist_entre_pole : '' }   onChange={e => update('dist_entre_pole', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.diameter_at_top ? item.diameter_at_top : '' }   onChange={e => update('diameter_at_top', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.diameter_at_bottom ? item.diameter_at_bottom : '' }   onChange={e => update('diameter_at_bottom', index, e)}/></div>
                <div className="col-md-1"><input type="text" className="form-control-plaintext" value={item.barcon_foot ? item.barcon_foot : '' }   onChange={e => update('barcon_foot', index, e)}/></div>
                <div className="col-md-1"><button  onClick={e => remove(index) }  className={"rounded-circle btn btn-sm"}> <FontAwesomeIcon icon={faTimes} /></button></div>
                
              
            </div>
          ))}
          </div>
        }

        <button onClick={add} className={"btn btn-primary"}> Ajouter</button>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={e => {cancel() ; setShow(false) }}>
          Annuler
        </button>
        <button className="btn btn-success" onClick={e => send()}>
          Envoyer
        </button>
      </Modal.Footer>
    </Modal>


{/* 
      <div className={"row col-md-6"}>
        <label>Société : </label>
        {data.site.society}
      </div>

      {data.site.society_name && (
        <div className={"row col-md-6"}>
          <label>Nom de la société si ASP : </label>
          {data.site.society_name}
        </div>
      )}

      <div className={"row col-md-6"}>
        <label>Fonction : </label>
        {data.site.agent_function}
      </div>

      <div className={"row col-md-6"}>
        <label>Numéro de téléphone : </label>
        {data.site.agent_phone_number}
      </div>

      <div className={"row col-md-6"}>
        <label>Propriétaire : </label>
        {data.site.site_owner}
      </div>

      <div className={"row col-md-6"}>
        <label>Numéro de téléphone : </label>
        {data.site.owner_phone_number}
      </div>

      <div className={"row col-md-6"}>
        <label>Contact ATM : </label>
        {data.site.atm_name}
      </div>

      <div className={"row col-md-6"}>
        <label>Numéro de téléphone : </label>
        {data.site.atm_phone_number}
      </div>

      <br />
      <h6>Information d'accès</h6>

      <div className={"row col-md-6"}>
        <label>Accès au site : </label>
        {data.site.access_means}
      </div>

      <div className={"row col-md-6"}>
        <label>Si Autres preciser : </label>
        {data.site.other_access_means}
      </div>

      <div className={"row col-md-6"}>
        <label>Commentaire : </label>
        {data.site.access_info_comment}
      </div>

      <div className={"row col-md-6"}>
        <label>Cheminement pour livraison : </label>
        {data.site.delivery_way}
      </div>

      <div className={"row col-md-6"}>
        <label>Si Autres preciser : </label>
        {data.site.other_delivery_way}
      </div>

      <div className={"row col-md-6"}>
        <label>Moyen de livraison : </label>
        {data.site.delivery_means}
      </div>

      <div className={"row col-md-6"}>
        <label>Si Autres preciser : </label>
        {data.site.other_delivery_means}
      </div>

      <br />
      <h6>Préparation Intervention Site - Accès Site - Accès Zone Technique - Accès Aériens</h6>

      <div className={"row col-md-6"}>
        <label>Conformitée : </label>
        {data.site.conformity}
      </div>

      <div className={"row col-md-6"}>
        <label>Description des non conformitées mineures : </label>
        {data.site.small_non_conformity_description}
      </div>

      <div className={"row col-md-6"}>
        <label>Description des non conformitées bloquantes : </label>
        {data.site.big_non_conformity_description}
      </div> */}

    {/*  <br />
      <h6>Photos</h6>

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <PhotoItem photo={photo} active={active} />
          ))}
        </div>
          )}*/ }

      {/* <br />
      <br />
      <div className={"row col-md-6"}>
        <label>Représentant client : </label>
        {data.site.client_representative}
      </div>

      <div className={"row col-md-6"}>
        <label>Représentant Ericsson : </label>
        {data.site.ericsson_representative}
      </div> */}

    </>
  );
};

export default Access;
