import React,{Component} from 'react';
export default class Success extends Component {
 render(){
     return(
           
        <div class="alert alert-success" role="alert">
        Tache planifié avec success
      </div>



     );
 }



}