import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SitesListContainer from "../Containers/GPM/Site/SitesContainer";
//import AddressNewContainer from "../Containers/Delivery/AddressNewContainer";
import AddressNewContainer from "../Containers/GPM/AddressNewContainer";
import SiteDetail from "../Components/Addresses/SiteDetail";
import TaskListContainer from "../Containers/GPM/TaskListContainer";
import GPMDashboardWrapper from "../Components/Dashboard/Pages/GPMDashboardWrapper";

function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class GPMRouter extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/gpm" component={TaskListContainer} />
        <Route exact path="/gpm/sites" component={SitesListContainer} />
        <Route exact path="/gpm/task" component={TaskListContainer} />
        <Route exact path="/gpm/sites/new" component={AddressNewContainer} />
        <Route path="/gpm/sites/detail/:id" component={SiteDetail} />
        <Route path='/gpm/dashboard' component={GPMDashboardWrapper} />
      </Switch>
    );
  }
}

export default connect(mapStateToProps)(GPMRouter);
