import React, { useState, Component, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import MoreInfoDialog, { Item } from '../../../Utils/MoreInfoDialog';

import moment from 'moment';

import GPMTests from './Tests';
import CheckOrTime from '../components/CheckOrTime';
import Restricted from '../../../Utils/Restricted';
import RenderComponent from '../../../Utils/RenderComponent';

import Constants from '../../../Utils/Constants';
import ApiService from '../../../Utils/ApiService';
import { useSelector } from 'react-redux';



const GPMTaskRow = ({task, key, /*exportPM,*/ exportGPM, deleteTask, returnTask, closeTask, ...props}) => {

    // const i = key;


    const [activeTest, selectTest] = useState("gpm_clockin")
    const [showDetail, setShowDetail] = useState(false);

    //export GPM
    const {session} = useSelector(state => ({session: state.session}));
    const [isLoading, setLoading] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState({});

    /*const exportGPM = (anomalies) => {

        setLoading(true);

		if(anomalies) {
			exportForm.current.action = Constants.apiEndPoints.EXPORT_TASK_ANOMALIES_GPM(task.task_id);
		} else {
			exportForm.current.action = Constants.apiEndPoints.EXPORT_TASK_GPM(task.task_id);
		}

        if(!dataLoaded)
            ApiService.fetchTaskDetail(task.task_id)
            .then(res => {
                if(res.data.success && res.data.total > 0) {
                    setDataLoaded(true);
                    setData(res.data.tasks[0]);
                    detailInput.current.value = JSON.stringify(res.data.tasks[0]);
                    exportForm.current.submit();
                    setLoading(false);
                }
                setLoading(false);

            })
        else {
            detailInput.current.value = JSON.stringify(data);
            exportForm.current.submit();
            setLoading(false);
        }
    }*/


    return (<React.Fragment>
      <form method="post" ref={exportForm} target={"_blank"} >
          <input type="hidden" name={"pm"} ref={detailInput} />
          <input type="hidden" name={"token"} value={session.user.token} />
      </form>

        <div className={"row "}>
        <div className={"col-md-12"}>

        <div className={"row ctable-row"}>


                <div className={"col-md-1 table-space-between"}>
                     <span>{task.task_id}</span>
                      {!task.task_status ? <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} /> : task.task_status === "return" ? <FontAwesomeIcon color="orange" icon={faUndo} /> : <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />}
                </div>


                <div className={"col-md-2"}>
                    <a className={"site-link"} href={"/agents/"+task.task_agent_id}>{task.task_agent}</a>
                </div>

                <div className={"col-md-2"}>
                    <a title={task.task_site_name} onClick={() => props.handlers.updateFilter({value: task.task_site_code}, 'site')} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>
                        {task.task_site_code}
                    </a>
                </div>

                <div className={"col-md-1"}>
                    {task.owner}
                </div>

                <div className={"col-md-2"}>
                    <span className={"task-plan-col"}>
                        <span className={"line task-"+task.task_delay}>
                            <span className={"line-label"}>Créé</span>
                            <span className={"line-value"}>{task.task_created}</span>
                        </span>
                        <span className={"line"}>
                            <span className={"line-label"}>Plan</span>
                            <span className={"line-value"}>{task.task_plan}</span>
                        </span>
                    </span>

                </div>

                <div className={"col-md-2 task-tests-col"}>
                    <span>{Object.keys(task.task_tests).filter((el) => el !== "gpm_clockin" && task.task_tests[el].data && task.task_tests[el].data.done).length+"/"+GPMTests.length}</span>

                </div>

                <div className={"col-md-2 task-details-col"}>

                    {task.geo && task.geo.lat && (
                        <a href={"https://www.google.com/maps/place/"+task.geo.lat+","+task.geo.lng+"/@"+task.geo.lat+","+task.geo.lng+",15z"} target='_blank' className="task-action-btn" >
                            <FontAwesomeIcon icon={faMapMarker}  />Localisation
                        </a>
                    )}

                    <button onClick={() => setShowDetail(!showDetail)} className="task-action-btn" ><FontAwesomeIcon icon={faChevronDown}  /> Info</button>

                  {/*  <button onClick={() => exportGPM(task) }  className="task-action-btn" ><FontAwesomeIcon icon={faFileExport}  /> Export</button>*/}

                    <MoreInfoDialog title={"more"}>
                      <Restricted scope={"gpm"} permission={"export_task"}>
                          <Item onClick={() => exportGPM(task) }  className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}  /> Export</Item>
                      </Restricted>
                        <Restricted scope={"gpm"} permission={"delete_task"}>
                            <Item onClick={() => deleteTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash}  /> Supprimer</Item>
                        </Restricted>
                        {/* <Item onClick={() => closeTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faLock}  />Cloturer</Item> */}
                        <Restricted scope={"gpm"} permission={"return_task"}>
                            <Item onClick={() => returnTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faUndo}  />Retourner</Item>
                        </Restricted>
                        <Restricted scope={"gpm"} permission={"read_task"}>
                            <Item onClick={() => exportGPM(task,true)}><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}  />Export NOK</Item>
                        </Restricted>
                        {/* <Item onClick={() => exportPM(task, true)}><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}  />Export NOK</Item> */}
                    </MoreInfoDialog>

                </div>

        </div>

        {!!showDetail && (

        <div className={"animateRow row"}>

                <div className={"pm-wrapper col-md-12 row no-gutters"}>

                    <div className={"col-md-2 pm-sidebar"}>
                        <ul>
                            {GPMTests.map(test => (
                                <li className={activeTest === test.key ? "active" : ""}>
                                    <a onClick={e => selectTest(test.key)}>
                                        <CheckOrTime task={task.task_tests[test.key]} />
                                        {test.name} <FontAwesomeIcon icon={faAngleRight} size={22} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={"col-md-10 pm-content"}>
                    {GPMTests.map(test => task.task_tests[test.key] && activeTest === test.key && (
                        <div className={"pm-test-single"}>
                            <h5>{test.name} {task.task_tests[test.key].data.finished_at ? <b>{moment(task.task_tests[test.key].data.finished_at).format('YYYY-MM-DD HH:mm').toString()} <br /></b> : ""}</h5>
                            <RenderComponent component={test.component} data={task.task_tests[test.key].data} />
                        </div>
                    ))}

                    </div>

                    {/* <div className={"col-md-3 pm-sidebar"}>
                        <ul>

                            <li className={activeTest === 'clockin' ? "active" : ""} >
                                <a onClick={e => selectTest('clockin')}>
                                    <CheckOrTime task={task.task_tests.clockin} />
                                    PM ClockIn <FontAwesomeIcon icon={faAngleRight} size={22} />
                                </a>
                            </li>

                            <li className={activeTest === 'gpm' ? "active" : ""} >
                                <a onClick={e => selectTest('gpm')}>
                                    <CheckOrTime task={task.task_tests.gpm} />
                                    GPM <FontAwesomeIcon icon={faAngleRight} size={22} />
                                </a>
                            </li>

                        </ul>
                    </div>


                    <div className={"col-md-9 pm-content"}>


                        {task.task_tests.clockin && activeTest === 'clockin' && (
                            <div className={"pm-test-single"}>
                                <h5>Clock-In </h5>
                                <ClockIn active={activeTest === 'clockin'} task={task.task_tests.clockin} />
                            </div>
                        )}

                        {task.task_tests.gpm && activeTest === 'gpm' && (
                            <div className={"pm-test-single"}>
                                <h5>GPM {task.task_tests.gpm.data.finished_at ? <b>{moment(task.task_tests.gpm.data.finished_at).format('YYYY-MM-DD HH:mm').toString()} <br /></b> : ""}</h5>
                                <GPMTest task={task.task_tests.gpm} />
                            </div>
                        )}

                    </div> */}

                </div>
        </div>
        )}

    </div>
    </div>
    </React.Fragment>

    )

}
export default GPMTaskRow;
