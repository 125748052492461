import React from "react";
import RenderChecks from "../../components/RenderChecks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const Return = ({ data,active ,...props }) => {
    const url = data && data.location ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z` : "";
    // console.log("rendrinf pickupS",data);
     //console.log("rendrinf props",props);
    if (data)
    return (
      <>

         {/* <RenderChecks data={data} active="true" /> */}
        {/* {data.geo && data.location.lat && (
                    <a href={"https://www.google.com/maps/place/"+data.geo.lat+","+data.geo.lng+"/@"+data.geo.lat+","+data.geo.lng+",15z"} target='_blank' className="task-action-btn" >
                      <FontAwesomeIcon icon={faMapMarker}  />Localisation
                    </a>
                  )} */}

        <b>BarCodes :</b>
        <br />
        {data.bar_code && (
            <div >

            {data.bar_code.map(item=>(
                <div className={"row "} key={item}>{item}</div>
              ))}
              </div>
            )}

        <b>Photos</b>
        <br />
        {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              <div key={"clock_photo_"+key} className={"test-photos-single"}>
                <PhotoItem photo={photo} active={1} />
              </div>
            ))}

          </div>

        )}






      </>
    );
}


export default Return;
