import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading, setFlash, closeFlash } from '../../../Redux/actions/index';
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLiraSign, faTimes } from '@fortawesome/free-solid-svg-icons'
import api from '../../../Utils/Api';
import ReturnOverlay from '../components/ReturnOverlay';
import Constants from '../../../Utils/Constants';
import TaskRow from './TaskRow';
import ApiService from '../../../Utils/ApiService';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { ThemeProvider } from 'react-bootstrap';
import Success from '../../Form/sucsess';
import Failure from '../../Form/failur';
import Modal from 'react-bootstrap/Modal';


const mapStateToProps = state => {
	return {
		session: state.session
	}
}

const mapDispatchToProps = (dispatch) =>
	({
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e)),
		setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
	})

class TaskList extends Component {

	constructor(props) {

		super(props);

		this.state = {
			rows: [],
			agents: [],
			showOverlay: false,
			taskid: "",
			agentid: 0,
			selected_pm: "",
			activeTest: "clockin",
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: false,
			showReturnOverlay: false,
			showNewInstallationOverlay: false,
			data: this.props.data,
			dataSent: {},
			alert_message: ""
			
		}

		this.loadForm()

	}

	toggleOverlay = () => this.setState({showOverlay: !this.state.showOverlay});

	selectTest = (e, test) => {

		if(window.ga)
			window.ga('send', 'event', 'TaskList', 'selectTest');
			http://localhost:3000
		e.preventDefault();
		this.setState({ activeTest: test })
	}

	deleteTask = (pmid) => {

		//const pmid = e.target.getAttribute('data-task-id');

		this.setState({
			selected_pm: pmid,
			showDeleteOverlay: true,
			deleteSteps: 1
		})
		
	}

	nextDeleteStep = () => {

		this.setState({
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: true,
			deleteSteps: 2
		})
	}

	confirmDelete = () => {

		this.props.isLoading();
		this.setState({ showDeleteOverlayConfirmation: false })

		const data = {
			pmid: this.state.selected_pm
		}

		api.post('/api/pm/delete', data)
			.then(() => {
				this.props.handlers.loadTasks()
			})
	}

	exportPM = (pm, anomalies) => {

		if(window.ga)
			window.ga('send', 'event', 'TaskList', 'exportPM');

		// let remote = Constants.apiURL;

		if(anomalies) {
			this.pmExportForm.action = Constants.apiURL+"/api/anomalies/checks_export/"+pm.task_id;
		} else {
			this.pmExportForm.action = Constants.apiURL+"/api/pm/export/igeneral/"+pm.task_id;
			//this.pmExportForm.action = Constants.apiURL+"/api/pm/export/pickup/"+pm.task_id;
		}

		this.pmInput.value = JSON.stringify(pm)
		this.userInfo.value = JSON.stringify(this.props.session.user)
		this.props.isLoading();
		this.pmExportForm.submit();
		this.props.doneLoading();
	}

	toggleReturnOverlay = () => this.setState({showReturnOverlay: !this.state.showReturnOverlay})

	returnTask = (task) => {

		this.setState({
			selected_pm: task,
			showReturnOverlay: true
		})
	}

	closeTask = taskid => {

		this.props.isLoading({ message: "Mise a jour en cours." });

		ApiService.closeTask(taskid)
			.then(res => {
				this.props.handlers.loadTasks();
				this.props.doneLoading();
			})
	}

	showNewInstallation = (taskid) =>{
		this.setState({
			showNewInstallationOverlay:true,
			dataSent: {
				...this.state.dataSent,
				taskid: taskid
			}
		})
    }

	newInstallation = () =>{
		this.setState({showNewInstallationOverlay: false})
		
		let data  = {task_id: this.state.taskid, agent_id: this.state.agentid}
		api.post('/api/planning/installation', this.state.dataSent).then(result => {
			if(result.data.success){
				console.log("true", result.data.res)
				this.setState({alert_message:"success"});
				setTimeout(() => {  this.setState({alert_message:""}); }, 2000);
			}else{
				this.setState({alert_message:"Error"});
			}
		})
    }

	updateAttState = (event, name) => {
		
		//let value = "";
	
		const value = event.target ? event.target.value : event.value;
		
		this.setState(
		{
			dataSent: {
				...this.state.dataSent, 
				[name]: value
			}
		}
		);
		
	};

	loadForm = () => {
		let that = this
		api.get("/api/task/form?token="+this.props.session.user.token).then(response => {
			if (response.data.success)
			  that.setState({
				agents: response.data.agents,
			  });
		  });
	}
	

	render() {

		const data = this.props.data;
		console.log('this is data', data)

		// if ( this.state.alert_message==="success"){
     	// 		return <Success/>;
		// }else if ( this.state.alert_message==="Error"){
		// 		return <Failure/>;
		// }

		return(
			<React.Fragment>
				<ReturnOverlay
					open={this.state.showReturnOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleReturnOverlay}
					reload={this.props.handlers.loadTasks}
					{...this.props}
				/>

				<form method="post" ref={ref => this.pmExportForm = ref}>
					<input type="hidden" name={"pm"} ref={ref => this.pmInput = ref} />
					<input type="hidden" name={"token"} value={this.props.session.user.token} />
					<input type="hidden" name={"userInfo"} ref={ref => this.userInfo = ref} />
				</form>

				<div className={this.state.showDeleteOverlay? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => this.setState({showDeleteOverlay: false }) }
							className={"ui-overlay-close btn btn-danger btn-sm"}>
						<FontAwesomeIcon icon={faTimes} />
						</button>
						<h5 className={"mb-4"}>Voulez-vous vraiment supprimer cette Delivery ?</h5>
						<button type="submit" className={"btn btn-success btn-sm mt-2"}>ANNULER</button>
						<button onClick={this.nextDeleteStep} type="submit" className={"btn btn-danger btn-sm ml-2 mt-2"}>OUI, SUPPRIMER</button>
					</div>
				</div>

				<div className={this.state.showDeleteOverlayConfirmation? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => this.setState({showDeleteOverlayConfirmation: false }) }
							className={"ui-overlay-close btn btn-danger btn-sm"}>
						<FontAwesomeIcon icon={faTimes} />
						</button>
						<h5 className={"mb-5"}>Êtes-vous sur de vouloir supprimer cette Delivery ?</h5>
						<button onClick={() => this.setState({showDeleteOverlayConfirmation: false }) }  type="submit" className={"btn btn-success btn-sm mt-2"}>Retour</button>
						<button onClick={this.confirmDelete}  type="submit" className={"btn btn-danger btn-sm mt-2 ml-2"}>Confirmer la suppression</button>
						<div>
							<span className={"font-exp"}>Attention, cette action est irreversible.</span>
						</div>
					</div>
				</div>

				<div className={this.state.showNewInstallationOverlay? "ui-overlay return-task open" : "ui-overlay return-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => this.setState({showNewInstallationOverlay: false }) }
							className={"ui-overlay-close btn btn-danger btn-sm"}>
						<FontAwesomeIcon icon={faTimes} />
						</button>
							<h5 className={"mb-4"}>planifier unee nouvelle Installation</h5>
							<label>Type de l'installation *</label>
							<Select
								
								classNamePrefix={"custom-select"}
								name={"installation_type"}
								options={[
									{label: 'New site', value: 'New site'},
									{label: 'Add 900', value: 'Add 900'},
								]}
								placeholder="Selectionner..."
								className={"d-block mt-1"}
								onChange={e => this.updateAttState(e, "installation_type")}
								required
							/>
							
							<label>Assignier a un agent *</label>
							<Select
								
								classNamePrefix={"custom-select"}
								name={"agent"}
								options={this.state.agents}
								placeholder="Agent"
								className={"d-block mt-1"}
								onChange={e => this.updateAttState(e, "agentid")}
								required
							/>
							{/* <select required name={"agentid"}  className={"form-control"}  onChange={this.handleChangeSelected} >
            					{this.state.agents.map((agent)=> <option key={agent.label} value={agent.value}>{agent.label}</option>
            				)}
          					</select> */}
							<label>Date de plannification *</label>
							<input required type="date" className={"form-control"} onChange={e => this.updateAttState(e,  "planning_date")} />
							
							
							<button  onClick={this.newInstallation} type="submit" className={"btn btn-primary btn-sm ml-2 mt-2"}>Confirmer</button>
						
						
					</div>
				</div>

				
				<Modal show={this.state.alert_message==="success"} >
					<Modal.Body><Success/></Modal.Body>
      			</Modal>

				<div className="col-md-12 ctable mb-3">
					<div className={"d-none d-sm-block"}>
						<div className={"row head-titles ctable-row"}>

							<div className={"col-md-1"}>
								ID
							</div>

							<div className={"col-md-1"}>
								<Trans i18nKey="label_agent" />
							</div>

							<div className={"col-md-1"}>
								Site Pickup
							</div>

							<div className={"col-md-1"}>
								Site Drop
							</div>

							<div className={"col-md-2"}>
								Owner
							</div>

							<div className={"col-md-2"}>
								<Trans i18nKey="label_datetime" />
							</div>

							<div className={"col-md-2"}>
								Etapes Delivery
							</div>

							<div className={"col-md-2 task-details-col"}>
								<Trans i18nKey="label_details" />
							</div>
						</div>
					</div>

					{data.tasks.map((task, i) =>
						<TaskRow
							key={task.task_id}
							exportPM={this.exportPM}
							task={task}
							handlers={this.props.handlers}
							deleteTask={this.deleteTask}
							returnTask={this.returnTask}
							closeTask={this.closeTask}
							showNewInstallation={this.showNewInstallation}
						/>
					)}

					{data.tasks.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucune {data.filters.task_type} trouvée</div>)}
				</div>
			</React.Fragment>
		)
	}
}

// export CheckOrTime;
export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
