import React from "react";
import ClockIn from "./ClockIn";
import Photos from "./Photos";
import Outdoor from "./Outdoor";
import Indoor from "./Indoor";
import SiteAccess from "./SiteAccess";
import PrintScreen from "./PrintScreen";
import Return from "./Return";
import Transmission from "./Transmission";
import TiltAfter from "./TiltAfter";


const InstallationTests = [
    {
        name: "Clock-in",
        key: "clockin",
        component: ClockIn,
    },
    {
        name: "SiteAccess",
        key: "siteaccess",
        component: SiteAccess,
    },
    {
        name: "Outdoor",
        key: "outdoor",
        component: Outdoor,
    },
    {
        name: "Indoor",
        key: "indoor",
        component: Indoor,
    },
    {
        name: "PrintScreen",
        key: "printscreen",
        component: PrintScreen,
    },
    {
        name: "Return",
        key: "return",
        component: Return,
    },
    {
        name: "TiltAfter",
        key: "tiltafter",
        component: TiltAfter,
    },
    {
        name: "Transmission",
        key: "transmission",
        component: Transmission,
    },

    // {
    //     name: "Accès au site",
    //     key: "access",
    //     component: Access,
    // },
    // {
    //     name: "Données du site",
    //     key: "data",
    //     component: Data,
    // },
    // {
    //     name: "Matériel radio & trans existant",
    //     key: "material",
    //     component: Material,
    // },
    // {
    //     name: "Environnement & alarmes",
    //     key: "alarm",
    //     component: Alarm,
    // },
    /*{
        name: "AC",
        key: "ac",
        component: AC,
    },
    {
        name: "Batteries",
        key: "batteries",
        component: Batteries,
    },
    {
        name: "Shelter",
        key: "shelter",
        component: Shelter,
    },
    {
        name: "BSS",
        key: "bss",
        component: BSS,
    },
    {
        name: "PDH-PAV-Redline",
        key: "pdh",
        component: PDH,
    },
    {
        name: "GE",
        key: "ge",
        component: GE,
    },
    {
        name: "Exterieure",
        key: "exterieur",
        component: Exterieur,
    },
    {
        name: "Alarms",
        key: "alarms",
        component: Alarms,
    },
    {
        name: "Spare Parts",
        key: "spareparts",
        component: SpareParts,
    },
    {
        name: "Anomalies",
        key: "anomalies",
        component: Anomalies,
    },
    {
        name: "Photos",
        key: "photos",
        component: Photos,
    },
    {
        name: "Securisation",
        key: "security",
        component: Security,
    },
    {
        name: "Sites colocalisé",
        key: "cosite",
        component: CoSite,
    },
    {
        name: "Sabotage",
        key: "sabotage",
        component: Sabotage,
    },*/
]

export default InstallationTests;
