import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";


import "react-datepicker/dist/react-datepicker.css";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  closeFlash,
  setFlash,
} from "../../Redux/actions/index";
import api from "../../Utils/Api";
import { connect } from "react-redux";
import Pagination from "../../Utils/Pagination";
import debounce from "lodash/debounce";
import Constants from "../../Utils/Constants";
import ChangeOwnerOverlay from "../Addresses/SiteList/ChangeOwnerOverlay";
import DeletePlanOverlay from "../Addresses/SiteList/DeletePlanOverlay";

import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { buildFilters } from "../../Utils/Helpers";
import ReactDatePicker from "react-datepicker";

import randomColor from "randomcolor";

const remote = Constants.apiURL;
const moment = require("moment");
moment.locale('fr', {
    months : 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 0, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f)),
  };
}

class CalendarPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    loading: true,
      sites: [],
      managers: [],
      planning: [],
      showOverlay: false,
      current: 1,
      pages: 1,
      viewMode: "cluster",
      limit: 1200,
      filters: {
        site: "",
        site_code: "",
        site_wilaya: "",
        site_cluster_manager: "",
        site_cluster: "",
        site_manager: "",
        site_owner: "",
        has_plan: true,
        plan_start: moment().startOf("month").format("YYYY-MM-DD"),
        plan_end: moment().endOf("month").format("YYYY-MM-DD"),
      },
      plan_start: "",
      plan_end: "",
      showOverlayPlan: false,
      selected_site: 0,
      exportData: [],
      showOwnerOverlay: false,
      showDeletePlanOverlay: false,
      selected_plan: "",
    };

    props.isLoading({
      message: "Chargement du planning",
      animated: true,
    });

    this.loadSites = debounce(this.loadSites, 250);
  }

  selectViewMode = viewMode => this.setState({viewMode, loading: true})

  updateFilter = (event, name) => {
    let that = this;
    const value = event.target ? event.target.value : event.value;

    this.setState(
      {
        loading: true,
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        console.log(that.state.filters);
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });

        that.loadSites(1);
      }
    );
  };

  updateFilterCheck = (event, name) => {
    let that = this;
    const value = event.target ? event.target.checked : false;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        console.log(that.state.filters);
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });

        that.loadSites(1);
      }
    );
  };

  loadSites = (p = this.state.current) => {
    const crits = buildFilters(this.state.filters);
    api
      .get(`/api/planning/fetch?page=${p}&limit=${this.state.limit}&${crits}`)
      .then((response) => {
        if (response.data.success) {
          this.setState({ ...response.data, loading: false });
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
          }
        }

        this.props.doneLoading();
      });
  };

  componentDidMount() {
    this.loadSites();
  }

  toggleOverlay = (e) => {
    e.preventDefault();
    this.setState({ showOverlay: !this.state.showOverlay });
  };

  toggleOverlayPlan = (siteId) => {
    this.setState({
      selected_site: siteId,
      showOverlayPlan: !this.state.showOverlayPlan,
    });
  };


  renderItems = () => {
      let groups, items = {};
      const green = randomColor({hue: "green", format: "rgba"});

      if(this.state.viewMode === "cluster") {
        groups = this.props.filters.clusters.map((item, k) => ({
            id: item.value,
            title: item.value,
            stackItems: true
        }));

        items = this.state.sites.map((site, k) => ({
            id: k+"_"+site.site_id,
            group: site.site_cluster,
            className: "custom-calendar-item"+(site.site_next_pm_done === "1" ? " task-done": " task-notdone") + (site.site_plan_respect === "0" ? " task-delay": " task-ontime"),
            title: site.site_code,
            start_time: moment(site.site_next_pm),
            end_time: moment(site.site_next_pm).add(1, "day"),
        }));
      } else if (this.state.viewMode === "agent") {
        groups = this.props.filters.agents.map((item, k) => ({
            id: item.value,
            title: item.label,
            stackItems: true
        }));

        items = this.state.sites.map((site, k) => ({
            id: k+"_"+site.site_id,
            group: site.site_next_pm_agent_id,
            title: site.site_code,
            className: "custom-calendar-item"+(site.site_next_pm_done === "1" ? " task-done": " task-notdone") + (site.site_plan_respect === "0" ? " task-delay": " task-ontime"),
            start_time: moment(site.site_next_pm),
            end_time: moment(site.site_next_pm).add(1, "day"),
        }));
      } else if (this.state.viewMode === "owner") {
        groups = this.props.filters.site_owners.filter(item => item.value && item.value !== "outsource" && item.value !== "all").map((item, k) => ({
            id: item.label,
            title: item.label,
            stackItems: true
        }));

        items = this.state.sites.map((site, k) => ({
            id: k+"_"+site.site_id,
            group: site.site_owner,
            title: site.site_code,
            className: "custom-calendar-item"+(site.site_next_pm_done === "1" ? " task-done": " task-notdone") + (site.site_plan_respect === "0" ? " task-delay": " task-ontime"),
            start_time: moment(site.site_next_pm),
            end_time: moment(site.site_next_pm).add(1, "day"),
        }));
      }


      if(this.state.loading) 
        this.setState({ loading: false });

      return {groups, items}
  }

  render() {

    if (this.props.filters.clusters.length === 0) return <></>;

    const {groups, items} = this.renderItems();

    const defaultTimeStart = moment(this.state.filters.plan_start);
    const defaultTimeEnd = moment(this.state.filters.plan_end);

    return (
      <React.Fragment>
        <div className={"container-header"}>Planning</div>

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              <h4>Recherche</h4>

              <div className={"form-group"}>
                <label>Periode</label>
                <br />
                <ReactDatePicker
                    onChange={date => this.updateFilter({value: moment(date).format("YYYY-MM-DD")}, "plan_start")}
                  selected={new Date(this.state.filters.plan_start)}
                  className={"form-control"}
                /><br />
              </div>

              <div className={"form-group"}>
                <ReactDatePicker
                  onChange={date => this.updateFilter({value: moment(date).format("YYYY-MM-DD")}, "plan_end")}
                  selected={new Date(this.state.filters.plan_end)}
                  className={"form-control"}
                />
              </div>

              <ul className="side-bar-menu">
                <li>
                  <button onClick={() => this.selectViewMode("cluster")} className={"btn btn-link"}>Vue par Cluster</button>
                </li>
                <li>
                  <button onClick={() => this.selectViewMode("owner")} className={"btn btn-link"}>Vue par Owner</button>
                </li>
                <li>
                  <button onClick={() => this.selectViewMode("agent")} className={"btn btn-link"}>Vue par Agent</button>
                </li>
                
              </ul>
            </div>
          </div>

          <div className={"col-md-10 overflow-hidden"}>
            <div>

                {!this.state.loading && (
                        <Timeline
                        minZoom={60 * 60 * 1000*24*7}
                        groups={groups}
                        items={items}
                        // keys={keys}
                        defaultTimeStart={moment(this.state.filters.plan_start)}
                        defaultTimeEnd={moment(this.state.filters.plan_end)}
                        />

                ) }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarPage);
