import React, { useContext } from "react";
import { FiltersContext } from "../FiltersWrap";
import Select from "react-select";
const SelectFilter = ({
  label,
  options,
  defaultValue,
  name,
  update,
  ...props
}) => {
  const context = useContext(FiltersContext);

  return (
    <div className={"form-group"}>
      <label>{label}</label>
      <Select
        classNamePrefix={"custom-select"}
        options={options}
        defaultValue={defaultValue}
        placeholder={"Selectionnez..."}
        onChange={(e) => context.updateFunc(e, name)}
      />
    </div>
  );
};

export default SelectFilter;
