import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";

class HistoryOverlay extends React.Component {
	

	constructor(props) {
		super(props);

		this.state = {
			history: [],
		}

	}


	render() {

		return (<div className={"ui-overlay"+ (this.props.open ? " open": "")}>
					<div className={"ui-overlay-content anomalie-history col-md-7"}>
						<button onClick={this.props.toggleOverlay} className={"ui-overlay-close btn btn-danger btn-sm"}>
						<FontAwesomeIcon icon={faTimes} />
						</button>
	
						<h5>Historique</h5>

						<p className={"text-left my-3"}>
							{this.props.anomalie}
						</p>
						

						<div className="col-md-12 ctable mb-3">
						
								<div className={"row head-titles ctable-row"}>

									<div className={"col-md-3"}>
										Date & heure
									</div>

									<div className={"col-md-5"}>
										Action
									</div>

									<div className={"col-md-2"}>
										Utilisateur
									</div>

									<div className={"col-md-2"}>
										Role
									</div>

								</div>

								{this.props.loading && <FontAwesomeIcon style={{fontSize: 24}} className={"mt-2"} spin={true} icon={faSpinner} />}
								
								{this.props.data.map(item => (

									<div className={"row ctable-row"}>

										<div className={"col-md-3"}>
											{item.created_at}
										</div>

										<div className={"col-md-5 text-left"}>
											{item.action}
										</div>

										<div className={"col-md-2"}>
											{item.username}
										</div>

										<div className={"col-md-2"}>
											{item.role}
										</div>

									</div>
								))}
							
						</div>
				</div>
				</div>
			)
	}
}
export default HistoryOverlay;
