import React from "react";


const GPMAssets = ({data, ...props}) => {

    console.log(props);
    return (
        <>

        GPM Assetss
        {Array.isArray(data.items) && data.items.map(asset => (
            <div className={"asset-item"}>
                <div className={"table-responsive col-md-12"}>
                    <table className={"table"}>
                        <tbody>
                            {asset.properties.map(prop => (
                                <tr>
                                    <th>{prop.prop_label}</th>
                                    <th>{prop.prop_value}</th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )) }
        {/* <RenderChecks isAc={true} data={task.data.checks} />


        {task.data.photos && (
                <div className={"test-photos-wrapper"}>
                {task.data.photos.map((photo, key) => (
                    <PhotoItem
                        photo={photo}
                        active={true}
                    />
                ))}
            </div>
        )} */}


        </>
    )
}

export default GPMAssets;