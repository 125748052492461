import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarDay,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import Restricted from "../../../Utils/Restricted";

const SiteGPMRow = ({
  site,
  reassignSite,
  planSite,
  session,
  ownerSite}) => {


  return (
    <React.Fragment>
      <div className={"row ctable-row"}>
        <div className={"col-md-1"}>
          <Link className={"task-action-btn"} to={'delivery/sites/detail/'+site.site_physique}>
            {site.site_code}
          </Link>
        </div>

        <div className={"col-md-2"}>
          <Link className={"task-action-btn"} to={'/delivery/sites/detail/'+site.site_physique}>{site.site_name}</Link>
        </div>

        <div className={"col-md-1"}>
          {site.site_assets.length}
        </div>

        <div className={"col-md-2"}>
            {site.site_address_wilaya.toUpperCase()}
        </div>

        <div className={"col-md-1  site-plan-col-date"}>
            <span>{site.site_owner}</span>
            <span>{site.site_manager_username}</span>
        </div>

        <div className={"col-md-2 site-plan-col-date"}>
          <span>{site.site_next_pm}</span>
          <span>{site.site_next_pm_agent}</span>
        </div>

        <div className={"col-md-2"}>
          <span>{site.site_owner_agent}</span>
        </div>

        <div className={"col-md-1 seperate task-details-col"}>
          <span className={"site-plan-col-action"}>

            <MoreInfoDialog title={"Actions"}>
              {/* <Item>
                  <Link to={'/sites/detail/'+site.site_id}><FontAwesomeIcon icon={faFileInvoice} className={"mr-2"}  /> Détail</Link>
              </Item> */}
              
              {/* <Item onClick={(e) => selectSite(site)}>
                <FontAwesomeIcon icon={faHistory} className={"mr-2"}  /> Historique
              </Item> */}

              <Restricted scope={"gpm"} permission={"edit_agent_site"}>
                <Item onClick={(e) => reassignSite(site, e)}>
                    <FontAwesomeIcon icon={faUser} className={"mr-2"}  /> Attribuer à un agent
                </Item>
              </Restricted>

              <Restricted scope={"gpm"} permission={"plan_task"}>
                <Item onClick={(e) => planSite(site, e)}>
                  <FontAwesomeIcon icon={faCalendarDay} className={"mr-2"}  /> Planifier GPM
                </Item>
              </Restricted>

              <Restricted scope={"gpm"} role={"cluster_manager"}>
                <Item onClick={(e) => ownerSite(site, e)}>
                    <FontAwesomeIcon icon={faRedoAlt} className={"mr-2"}  /> Change Owner
                </Item>
              </Restricted>

              {(session.role === "admin" || session.role === "cluster_manager" || (session.role === "manager" && session.owner === "O&M")) && (
                <>
                {/* <Item onClick={(e) => editSite(site, e)}>
                    <FontAwesomeIcon icon={faEdit} className={"mr-2"}  /> Modifier
                </Item> */}
                </>
              )}
            </MoreInfoDialog>
            

          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SiteGPMRow;