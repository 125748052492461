import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";




const GPMPhotos = ({data, ...props}) => {


    return (
        <>
            {data.photos && (
                    <div className={"test-photos-wrapper"}>
                    {data.photos.map((photo, key) => (
                        <PhotoItem
                            photo={photo}
                            active={true}
                        />
                    ))}
                </div>
            )} 
        </>
    )
};

export default GPMPhotos;