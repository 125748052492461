import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';


import * as actions from './../../Redux/actions/index';

import Brands from './components/Brands';
import Users from './components/Users';
import Config from './components/Config';

import Files from './Files/Files';
import Notifications from './Notifications';
import TaskSettings from './Tasks';
import Problems from './components/Problems';
import Anomalies from './components/Anomalies';



const mapStateToProps = state => {
    return {
        auth: state.session.auth,
        user: state.session.user,
        filters: state.filters
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAuth: user => dispatch(actions.setAuthAction(user)),
        isLoading: e => dispatch(actions.startLoading(e)),
        doneLoading: () => dispatch(actions.doneLoading()),
        setFlash: (f) => dispatch(actions.setFlash(f)),
        closeFlash: (f) => dispatch(actions.closeFlash(f))
    }
}



class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {


        }



    }

    componentDidMount() {




    }



    render() {
            const baseUrl = "/settings";

            return (

                    <div >
                    < div className = { "container-header" } >
                    Configuration </div>

                    <div className = { "clear" } > < /div>

                    <div className = { "row" } >

                    <div className = { "col-md-2" } >
                    <div className = { "side-filters" } >

                    <ul className = { "side-bar-menu" } >
                    <li > < Link to = { baseUrl } > Configuration générale < /Link></li >
                    <li > < Link to = { baseUrl + "/brands" } > Marques & modèles < /Link></li >
                    <li > < Link to = { baseUrl + "/notifications" } > Notifications < /Link></li >
                    <li > < Link to = { baseUrl + "/files" } > Fichiers & guides < /Link></li >
                    <li > < Link to = { baseUrl + "/problems" } > Problèmes & alarmes < /Link></li >
                    <li > < Link to = { baseUrl + "/anomalies" } > Anomalies < /Link></li >
                    { /* <li><Link to={baseUrl+'/tasks'}>Tâches</Link></li> */ }
                    { /* <li><Link to={baseUrl+'/brands'}>Marques & modèle</Link></li> */ }
                    { /* <li><Link to={baseUrl+'/users'}>Utilisateurs</Link></li> */ }
                    </ul>

                    </div>
                    </div>
                    <div className = { "col-md-10" } >

                    <Switch >
                    <Route exact path = '/settings/brands' render = { () => <Brands {...this.props }/>} / >
                    <Route exact path = '/settings/users' render = { () => <Users {...this.props }/>} / >
                    <Route exact path = '/settings/files' component = { Files }/>
                    <Route exact path = '/settings/notifications' component = { Notifications }/>
                    <Route exact path = '/settings/tasks' component = { TaskSettings }/>
                    <Route exact path = '/settings' render = { () => <Config {...this.props }/>} / >
                    <Route exact path = '/settings/problems' render = { () => <Problems {...this.props } />} / >
                    <Route exact path = '/settings/anomalies' render = { () => <Anomalies {...this.props } />} / >
                    </Switch>
                  </div>
                </div>
              </div>
            )
          }
        }
export default connect(mapStateToProps, mapDispatchToProps)(Settings)
