import React from "react";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, Link } from 'react-router-dom';
import { faEdit, faHistory, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";


class TaskProblemRow extends React.Component {

	constructor(props) {
		super(props);
		
		this.state = {
			anomalie: this.props.anomalie,
			editing: false,
			selected: false,
			temp: {}
		}
	}

	componentDidMount() {
		document.addEventListener('keyup', this.handleEscape)
	}

	handleEscape = (e) => {
		if(e.keyCode === 27) {
			this.setState({editing: false});
		}
	}

	selectMulti = (id) => {
		this.props.toggleSelectSingleAnomalie(id);
	}

	enableAnomalieEditing = () => {
		this.setState({
			editing: true
		})
	}

	// finishAnomalieEditing = () => {
	// 	console.log({...this.state.anomalie, ...this.state.temp})
	// 	this.setState({
	// 		anomalie: {...this.state.anomalie, ...this.state.temp}
	// 	});

	// 	this.props.isLoading({
	// 		message: "Mise à jour en cours",
	// 		animated: true
	// 	});

	// 	api.post('/api/anomalies/update/'+this.state.anomalie.id, this.state.temp)
	// 	.then(response => {
	// 		console.log(response.data)
	// 	})
	// 	.then(() => {
	// 		this.props.doneLoading()
	// 	})

	// 	this.setState({ editing: false })
	// }

	componentDidUpdate(props) {
		if(this.props.anomalie.id !== this.state.anomalie.id)
			this.setState({
				anomalie: this.props.anomalie
			});
	}

	updateAnomalie = (field, labelValue) => {
		const temp = this.state.temp;
		temp[field] = labelValue.value;

		this.setState({
			temp: temp
		});
	}

	render() {
		const {key} = this.props;
		const anomalie = this.state.anomalie;

		return (
			<div className={"row ctable-row"+ (this.props.selectMode ? " row-select-mode" : "") + (this.props.selected ? " row-selected" : "")} onClick={() => this.props.selectMode ? this.selectMulti(anomalie.id) : false}>
									
					{/* <div className={"col-md-1"}>
						#{anomalie.id}
					</div> */}
					
					<div className={"col-md-3 anomalies-status-col no-gutters"}>
						{anomalie.created_at} <br />
                        {anomalie.username}
					</div>
                    
                    <div className={"col-md-2 site-plan-col-date"}>
						<span>{anomalie.site_code}</span>						
					</div>

					<div className={"col-md-2 no-gutters"}>
						{this.state.editing 
							? (<>
								<Select
									onChange={(v) => this.updateAnomalie('type', v)}
									classNamePrefix={"custom-select"}
									className={"col-md-12"}
									options={this.props.filters.anomalies_types}
									defaultValue={{label: anomalie.type, value: anomalie.type}}
								/>
								</>
							) 
							: anomalie.type || ("N/D")
						}
					</div>

					<div className={"col-md-5"}>
						{anomalie.content}
					</div>



					



					{/* <div className={"col-md-2 task-details-col"}>
						{this.props.selected && (
								<button className={"task-action-btn"} onClick={() => this.selectMulti(anomalie.id)}>
									<FontAwesomeIcon color={"#09bc8a"} style={{fontSize: 28}} icon={faCheckCircle} />
								</button>
						)}
						{!this.props.selectMode && (
							<>
							<button className="task-action-btn" onClick={e => this.props.selectAnomalie(anomalie)} ><FontAwesomeIcon icon={faHistory}  /> Historique</button>
							{!this.state.editing 
								? <button onClick={() => this.enableAnomalieEditing(key)} className="task-action-btn" ><FontAwesomeIcon icon={faEdit}  /> Modifier</button>
								: <button onClick={() => this.finishAnomalieEditing(key)} className="task-action-btn" ><FontAwesomeIcon className={"text-success"} icon={faCheckCircle}  /> Sauvegarder</button>
							}
							</>

						)}
					</div> */}


				
			</div>
			
		)
	}
}

export default TaskProblemRow;