import React from "react"


export default class Assets extends React.Component {
    render() {
        return (<>
        
        <div className={"row"}>

            <div className={"col-md-6"}>
                <div className={"asset-bloc"}>

                    <h5>Air Conditionning</h5>
                    
                    <div className={"ctable"}>
                        <div className={"ctable-row head-titles row"}>
                            <div className={"col-md-2"}>Type</div>
                            <div className={"col-md-2"}>Marque</div>
                            <div className={"col-md-2"}>Capacity</div>
                            <div className={"col-md-3"}>Numéro de série</div>
                            <div className={"col-md-3"}>Date d'installation</div>
                        </div>
                        <div className={"ctable-row row"}>
                            <div className={"col-md-2"}>Monobloc</div>
                            <div className={"col-md-2"}>ENIEM</div>
                            <div className={"col-md-2"}>18000</div>
                            <div className={"col-md-3"}>NSAC321</div>
                            <div className={"col-md-3"}>01/01/2019</div>
                        </div>
                    </div>
                </div>


                <div className={"asset-bloc"}>

                    <h5>Power Supply</h5>
                    
                    <div className={"ctable"}>
                        <div className={"ctable-row head-titles row"}>
                            <div className={"col-md-4"}>Type</div>
                            <div className={"col-md-4"}>Numéro de série</div>
                            <div className={"col-md-4"}>Date d'installation</div>
                        </div>
                        <div className={"ctable-row row"}>
                            <div className={"col-md-4"}>Huawei</div>
                            <div className={"col-md-4"}>PSNS654</div>
                            <div className={"col-md-4"}>10/10/2019</div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={"col-md-6"}>
                
                <div className={"asset-bloc"}>

                    <h5>Batteries</h5>
                    
                    <div className={"ctable"}>
                        <div className={"ctable-row head-titles row"}>
                            <div className={"col-md-2"}>Marque</div>
                            <div className={"col-md-2"}>Nombre</div>
                            <div className={"col-md-3"}>AH</div>
                            <div className={"col-md-2"}>Rénové</div>
                            <div className={"col-md-3"}>Date d'installation</div>
                        </div>
                        <div className={"ctable-row row"}>
                            <div className={"col-md-2"}>PowerSafe</div>
                            <div className={"col-md-2"}>6</div>
                            <div className={"col-md-3"}>170AH</div>
                            <div className={"col-md-2"}>NON</div>
                            <div className={"col-md-3"}>01/01/2019</div>
                        </div>
                    </div>
                </div>


                <div className={"asset-bloc"}>

                    <h5>Generators</h5>
                    
                    <div className={"ctable"}>
                        <div className={"ctable-row head-titles row"}>
                            <div className={"col-md-2"}>Type</div>
                            <div className={"col-md-2"}>Type ATS</div>
                            <div className={"col-md-3"}>Frequence</div>
                            <div className={"col-md-2"}>Heure de marche</div>
                            <div className={"col-md-3"}>Autonomie</div>
                        </div>
                        <div className={"ctable-row row"}>
                            <div className={"col-md-2"}>GEType</div>
                            <div className={"col-md-2"}>SGN</div>
                            <div className={"col-md-3"}>50/60</div>
                            <div className={"col-md-2"}>900</div>
                            <div className={"col-md-3"}>100L</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        </>)
    }
}