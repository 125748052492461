const notificationsState = {
  notifications: [],
  notificationsHash: null,
  inlineNotification: {
      active: false,
      content: "test"
  }
};

export default (state = notificationsState, action) => {
  if (action.type === "UPDATE_NOTIFICATIONS") {
    return {
      ...state,
      notifications: action.payload.notifications,
      notificationsHash: action.payload.hash
    };
  } else if (action.type === "READ_NOTIFICATIONS") {
    return {
      ...state,
      notifications: state.notifications.map(x => ({ ...x, read: true }))
    };
  } else {
    return state;
  }
};
