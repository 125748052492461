import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import api from "../../../Utils/Api";


export default class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history_data: [],
            loading: true
        }
    }


    componentDidMount() {
        api.get("/api/sites/history/" + this.props.match.params.id).then((response) => {
            console.log(response.data)
            this.setState({
                loading: false,
                history_data: response.data.data,
            });
        });
   
    }

    

    render() {
        return (<>
        
        <h3>Historique</h3>
        
        <div className="col-md-12 ctable my-3">
            <div className={"row head-titles ctable-row"}>
            <div className={"col-md-3"}>Date & heure</div>

            <div className={"col-md-5"}>Action</div>

            <div className={"col-md-2"}>Utilisateur</div>

            <div className={"col-md-2"}>Role</div>
            </div>

            {this.state.loading && (
            <FontAwesomeIcon
                style={{ fontSize: 24 }}
                className={"mt-2"}
                spin={true}
                icon={faSpinner}
            />
            )}

            {this.state.history_data.map((item) => (
            <div className={"row ctable-row"}>
                <div className={"col-md-3"}>{item.created_at}</div>

                <div className={"col-md-5 text-left"}>{item.action}</div>

                <div className={"col-md-2"}>{item.username}</div>

                <div className={"col-md-2"}>{item.role}</div>
            </div>
            ))}
        </div>

        </>)
    }
}