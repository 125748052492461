import React from "react";

const SpareParts = ({ data, ...props }) => {
  return (
    <>
      {data.items &&
        data.items.map(item => (
          <>
            <div className={"row col-md-6"}>
              <label>Item : </label>
              {item.label}
            </div>

            <div className={"row col-md-6"}>
              <label>Unit : </label>
              {item.unit}
            </div>

            <div className={"row col-md-6"}>
              <label>Quantité : </label>
              {item.quantity}
            </div>

            <div className={"row col-md-6"}>
              <label>Where : </label>
              {item.where}
            </div>

            <div className={"row col-md-6"}>
              <label>Remarks : </label>
              {item.remarks}
            </div>

            <hr />
          </>
        ))}
    </>
  );
};

export default SpareParts;
